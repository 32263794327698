import React from "react";
import { Box, useTheme } from "@mui/material";
import Markdown from "react-markdown";
import { SxProps } from "@mui/system";

export default function MarkdownDescriptionDisplay({
   description,
   sx,
   elevation,
}: {
   description: string;
   sx?: SxProps;
   elevation?: number;
}) {
   const theme = useTheme();

   return (
      <Box
         sx={{
            padding: theme.spacing(1),
            overflow: "auto",
            maxHeight: "600px",
            minWidth: 200,
            width: "100%",
            ...sx,
         }}
      >
         <Markdown>{description}</Markdown>
      </Box>
   );
}
