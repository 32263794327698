import React, { FC } from "react";
import { Avatar, Box, CardHeader, Grow, IconButton, List, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { PromoterSummaryResponse } from "../../rest.client/usePromotersApi";
import { toPromoter } from "../../navPaths";
import { SxProps } from "@mui/system";

const rootSx: SxProps = {
   cursor: "pointer",
   width: { xs: `90%`, sm: `400px` },
   borderRadius: "5px",
   padding: "10px",
   boxSizing: "border-box",
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
   backdropFilter: "blur(15px) sepia(1)",
   "&:hover": {
      backdropFilter: "blur(30px) sepia(0)",
      boxShadow: `0 0 1vmin 0.3vmin rgba(0,0,0,1)`,
   },
   background: "linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.5), rgba(0,0,0,0.8))",
};

const PromoterTile: FC<{ promoter: PromoterSummaryResponse }> = ({ promoter }) => {
   const nav = useNavigate();
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   return (
      <Grow in={true}>
         <Box onClick={() => nav(toPromoter(promoter.id))} sx={rootSx}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", margin: theme.spacing(1) }}>
               <Box>
                  <Avatar
                     sx={{ width: 96, height: 96 }}
                     alt={promoter.name + " logo"}
                     src={promoter.logoUrl != null ? promoter.logoUrl : "/logo512.png"}
                  />
               </Box>
               <CardHeader
                  action={<IconButton aria-label="settings"></IconButton>}
                  title={
                     <Typography variant={"h6"} overflow={"wrap"} whiteSpace={"normal"} fontWeight={"bold"}>
                        {promoter.name}
                     </Typography>
                  }
                  subheader={
                     promoter.location != null && promoter.location !== "" ? (
                        <Box sx={{ display: "flex" }}>
                           <LocationOnIcon />
                           <Typography
                              variant={"caption"}
                              width={smallScreen ? "150px" : "200px"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                           >
                              {promoter.location}
                           </Typography>
                        </Box>
                     ) : (
                        <Box></Box>
                     )
                  }
               />
            </Box>
            <Box>
               <List dense={true} sx={{ display: "flex", justifyContent: "space-between" }}></List>
            </Box>
         </Box>
      </Grow>
   );
};

export default PromoterTile;
