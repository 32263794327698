import React from "react";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import {
   ComponentMode,
   PLAYER1_TEXT_COLOR,
   PLAYER2_TEXT_COLOR,
   PLAYER3_TEXT_COLOR,
   PLAYER4_TEXT_COLOR,
} from "../../UI_CONST";
import UserSelectorAutocomplete from "../UserSelectorAutocomplete";
import CardPlaceHolderComponent from "../playerAndCommander/CardPlaceHolderComponent";
import { MutableGame } from "../MutableGame";
import { empty } from "../../utils";

const rootContainerSx: SxProps = {
   width: "100%",
   height: "100%",
   position: "absolute",
   left: 0,
   top: 0,
   display: "flex",
   flexWrap: "wrap",
   justifyContent: "center",
   alignContent: "flex-start",
   paddingTop: "1vh",
};

const playerAndCommandersContainerSx: SxProps = {
   // border: "1px rgba(0,0,0,0.4) solid",
   // boxSizing: "border-box",
   // padding: "1vh",
};

const commandersContainerSx: SxProps = {
   width: "41vw",
   height: "33vh",
   // border: "1px rgba(255,0,0,0.1) solid",
   // boxSizing: "border-box",
   display: "flex",
   position: "relative",
   margin: "1vh",
};

interface IMobilePlayersStepComponentProps {
   game: MutableGame;
}

const MobilePlayersStepComponent: React.FC<IMobilePlayersStepComponentProps> = ({ game }) => {
   const showPlayer1SecondaryCommanderPicker: boolean = Boolean(
      game.player1CommanderPair?.commander1.requiresBackground ||
         game.player1CommanderPair?.commander1.partner ||
         game.player1CommanderPair?.commander2 != null
   );
   const showPlayer2SecondaryCommanderPicker: boolean = Boolean(
      game.player2CommanderPair?.commander1.requiresBackground ||
         game.player2CommanderPair?.commander1.partner ||
         game.player2CommanderPair?.commander2 != null
   );
   const showPlayer3SecondaryCommanderPicker: boolean = Boolean(
      game.player3CommanderPair?.commander1.requiresBackground ||
         game.player3CommanderPair?.commander1.partner ||
         game.player3CommanderPair?.commander2 != null
   );
   const showPlayer4SecondaryCommanderPicker: boolean = Boolean(
      game.player4CommanderPair?.commander1.requiresBackground ||
         game.player4CommanderPair?.commander1.partner ||
         game.player4CommanderPair?.commander2 != null
   );

   return (
      <Box sx={rootContainerSx}>
         <Box sx={playerAndCommandersContainerSx}>
            <UserSelectorAutocomplete
               mode={ComponentMode.VIEW}
               playerColor={PLAYER1_TEXT_COLOR}
               playerIndex={1}
               playerSummary={game.player1Summary}
               selectPlayerHandler={empty}
               otherPlayers={[]}
               setWinner={empty}
               wonGame={game.winningUser === "PLAYER_1"}
            />
            <Box sx={commandersContainerSx}>
               {showPlayer1SecondaryCommanderPicker ? (
                  <CardPlaceHolderComponent
                     card={game.player1CommanderPair?.commander2}
                     setCard={empty}
                     disallowedCards={[]}
                     width={35}
                     position={"absolute"}
                     left={0}
                     top={0}
                     hoverText={"2nd commander"}
                     filterCommanders={false}
                     filterPartners={false}
                     filterBackgrounds={false}
                     filterFriendsForever={false}
                     mode={ComponentMode.VIEW}
                     cardWidthUnit={"vw"}
                  />
               ) : (
                  <></>
               )}
               <CardPlaceHolderComponent
                  card={game.player1CommanderPair?.commander1}
                  setCard={empty}
                  disallowedCards={[]}
                  width={35}
                  position={showPlayer1SecondaryCommanderPicker ? "absolute" : "relative"}
                  right={showPlayer1SecondaryCommanderPicker ? 0 : undefined}
                  bottom={showPlayer1SecondaryCommanderPicker ? 0 : undefined}
                  hoverText={"1st commander"}
                  filterCommanders={false}
                  filterPartners={false}
                  filterBackgrounds={false}
                  filterFriendsForever={false}
                  mode={ComponentMode.VIEW}
                  cardWidthUnit={"vw"}
               />
            </Box>
         </Box>
         <Box sx={playerAndCommandersContainerSx}>
            <UserSelectorAutocomplete
               mode={ComponentMode.VIEW}
               playerColor={PLAYER2_TEXT_COLOR}
               playerIndex={2}
               playerSummary={game.player2Summary}
               selectPlayerHandler={empty}
               otherPlayers={[]}
               setWinner={empty}
               wonGame={game.winningUser === "PLAYER_2"}
            />
            <Box sx={commandersContainerSx}>
               {showPlayer2SecondaryCommanderPicker ? (
                  <CardPlaceHolderComponent
                     card={game.player2CommanderPair?.commander2}
                     setCard={empty}
                     disallowedCards={[]}
                     width={35}
                     position={"absolute"}
                     left={0}
                     top={0}
                     hoverText={"2nd commander"}
                     filterCommanders={false}
                     filterPartners={false}
                     filterBackgrounds={false}
                     filterFriendsForever={false}
                     mode={ComponentMode.VIEW}
                     cardWidthUnit={"vw"}
                  />
               ) : (
                  <></>
               )}
               <CardPlaceHolderComponent
                  card={game.player2CommanderPair?.commander1}
                  setCard={empty}
                  disallowedCards={[]}
                  width={35}
                  position={showPlayer2SecondaryCommanderPicker ? "absolute" : "relative"}
                  right={showPlayer2SecondaryCommanderPicker ? 0 : undefined}
                  bottom={showPlayer2SecondaryCommanderPicker ? 0 : undefined}
                  hoverText={"1st commander"}
                  filterCommanders={false}
                  filterPartners={false}
                  filterBackgrounds={false}
                  filterFriendsForever={false}
                  mode={ComponentMode.VIEW}
                  cardWidthUnit={"vw"}
               />
            </Box>
         </Box>
         <Box sx={playerAndCommandersContainerSx}>
            <Box sx={commandersContainerSx}>
               {showPlayer4SecondaryCommanderPicker ? (
                  <CardPlaceHolderComponent
                     card={game.player4CommanderPair?.commander2}
                     setCard={empty}
                     disallowedCards={[]}
                     width={35}
                     position={"absolute"}
                     left={0}
                     top={0}
                     hoverText={"2nd commander"}
                     filterCommanders={false}
                     filterPartners={false}
                     filterBackgrounds={false}
                     filterFriendsForever={false}
                     mode={ComponentMode.VIEW}
                     cardWidthUnit={"vw"}
                  />
               ) : (
                  <></>
               )}
               <CardPlaceHolderComponent
                  card={game.player4CommanderPair?.commander1}
                  setCard={empty}
                  disallowedCards={[]}
                  width={35}
                  position={showPlayer4SecondaryCommanderPicker ? "absolute" : "relative"}
                  right={showPlayer4SecondaryCommanderPicker ? 0 : undefined}
                  bottom={showPlayer4SecondaryCommanderPicker ? 0 : undefined}
                  hoverText={"1st commander"}
                  filterCommanders={false}
                  filterPartners={false}
                  filterBackgrounds={false}
                  filterFriendsForever={false}
                  mode={ComponentMode.VIEW}
                  cardWidthUnit={"vw"}
               />
            </Box>
            <UserSelectorAutocomplete
               mode={ComponentMode.VIEW}
               playerColor={PLAYER4_TEXT_COLOR}
               playerIndex={4}
               playerSummary={game.player4Summary}
               selectPlayerHandler={empty}
               otherPlayers={[]}
               setWinner={empty}
               wonGame={game.winningUser === "PLAYER_4"}
            />
         </Box>
         <Box sx={playerAndCommandersContainerSx}>
            <Box sx={commandersContainerSx}>
               {showPlayer3SecondaryCommanderPicker ? (
                  <CardPlaceHolderComponent
                     card={game.player3CommanderPair?.commander2}
                     setCard={empty}
                     disallowedCards={[]}
                     width={35}
                     position={"absolute"}
                     left={0}
                     top={0}
                     hoverText={"2nd commander"}
                     filterCommanders={false}
                     filterPartners={false}
                     filterBackgrounds={false}
                     filterFriendsForever={false}
                     mode={ComponentMode.VIEW}
                     cardWidthUnit={"vw"}
                  />
               ) : (
                  <></>
               )}
               <CardPlaceHolderComponent
                  card={game.player3CommanderPair?.commander1}
                  setCard={empty}
                  disallowedCards={[]}
                  width={35}
                  position={showPlayer3SecondaryCommanderPicker ? "absolute" : "relative"}
                  right={showPlayer3SecondaryCommanderPicker ? 0 : undefined}
                  bottom={showPlayer3SecondaryCommanderPicker ? 0 : undefined}
                  hoverText={"1st commander"}
                  filterCommanders={false}
                  filterPartners={false}
                  filterBackgrounds={false}
                  filterFriendsForever={false}
                  mode={ComponentMode.VIEW}
                  cardWidthUnit={"vw"}
               />
            </Box>
            <UserSelectorAutocomplete
               mode={ComponentMode.VIEW}
               playerColor={PLAYER3_TEXT_COLOR}
               playerIndex={3}
               playerSummary={game.player3Summary}
               selectPlayerHandler={empty}
               otherPlayers={[]}
               setWinner={empty}
               wonGame={game.winningUser === "PLAYER_3"}
            />
         </Box>
      </Box>
   );
};

export default MobilePlayersStepComponent;
