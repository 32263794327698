import React, { useState } from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { RemotePromoter } from "../useRemotePromoter";
import MarkdownDescriptionDisplay from "../../pageTournament/MarkdownDescriptionDisplay";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PromoterControls from "./PromoterControls";
import CreateOrEditPromoterDialog from "../CreateOrEditPromoterDialog";

const PromoterDetails: React.FC<{ remotePromoter: RemotePromoter }> = ({ remotePromoter }) => {
   const promoterResponse = remotePromoter.promoterFullResponse;
   const [openEditPromoter, setOpenEditPromoter] = useState(false);
   const theme = useTheme();
   return (
      <Box
         sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
         }}
      >
         <Box>
            <Avatar
               sx={{ width: 256, height: 256, alignSelf: "flex-start" }}
               alt={remotePromoter.promoterData.name + " logo"}
               src={promoterResponse?.logoUrl != null ? promoterResponse.logoUrl : "/logo512.png"}
            />
         </Box>
         <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", gap: theme.spacing(1) }}>
               <Typography
                  variant={"h3"}
                  overflow={"wrap"}
                  whiteSpace={"normal"}
                  fontWeight={"600"}
                  textAlign={"center"}
               >
                  {promoterResponse?.name}
               </Typography>
               {promoterResponse?.location != null && promoterResponse?.location !== "" ? (
                  <Box sx={{ display: "flex" }}>
                     <LocationOnIcon />
                     <Typography
                        variant={"caption"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                     >
                        {promoterResponse.location}
                     </Typography>
                  </Box>
               ) : null}
               <PromoterControls remotePromoter={remotePromoter} onClickEdit={() => setOpenEditPromoter(true)} />
               <MarkdownDescriptionDisplay description={remotePromoter.promoterData.description || ""} elevation={0} />
            </Box>
         </Box>
         {openEditPromoter && (
            <CreateOrEditPromoterDialog
               open={openEditPromoter}
               close={() => setOpenEditPromoter(false)}
               promoterId={promoterResponse?.id}
            />
         )}
      </Box>
   );
};

export default PromoterDetails;
