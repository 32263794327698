import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RemotePromoter } from "../useRemotePromoter";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CustomIconButton from "../../common/CustomIconButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { ComponentMode } from "../../UI_CONST";
import { empty } from "../../utils";
import UserSelectorAutocomplete from "../../gameFeatures/UserSelectorAutocomplete";
import { Maybe } from "../../TYPE";
import { UserSummary } from "../../rest.client/useApplicationApi";
import UserChip from "../../common/UserChip";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "../../common/HelpIcon";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";

export default function PromoterAdminTeamExpander({ remotePromoter }: { remotePromoter: RemotePromoter }) {
   const theme = useTheme();
   const response = remotePromoter.promoterFullResponse;
   const displayMessage = useAddMessageItem();
   const admins = response?.admins || [];
   const [selectedUser, setSelectedUser] = useState<Maybe<UserSummary>>();

   return (
      <Accordion defaultExpanded={false} sx={{ width: "100%" }}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
               }}
            >
               <AdminPanelSettingsIcon />
               <Typography>Admin team</Typography>
               {response?.isAdmin && (
                  <HelpIcon
                     message={
                        "This displays the current admin team for your promoter account. " +
                        "You can add or remove admins. Admins will have full organiser access to all tournaments and can modify all aspects of the promoter account."
                     }
                  />
               )}
            </Box>
         </AccordionSummary>
         <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
            {response?.isAdmin && (
               <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                  <UserSelectorAutocomplete
                     mode={ComponentMode.EDIT}
                     playerColor={theme.palette.text.primary}
                     playerIndex={1}
                     hideWinnerSelector={true}
                     playerSummary={selectedUser}
                     selectPlayerHandler={setSelectedUser}
                     hideAnonymousSelector={true}
                     otherPlayers={admins}
                     setWinner={empty}
                     wonGame={false}
                     showStatsButton={false}
                     labelText={"Search user"}
                     sx={{ width: "300px", margin: "initial" }}
                  />
                  <CustomIconButton
                     icon={<PersonAddIcon />}
                     buttonProps={{ disabled: selectedUser == null }}
                     onClick={async () => {
                        if (selectedUser) {
                           await remotePromoter.addAdmin(selectedUser?.id);
                           setSelectedUser(null);
                           displayMessage(selectedUser.name + " added as admin!", "success");
                        }
                     }}
                  >
                     Add
                  </CustomIconButton>
               </Box>
            )}
            {admins.length === 0 && (
               <Alert severity={"info"}>No users found in the admin team for this promoter account.</Alert>
            )}
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1), flexWrap: "wrap" }}>
               {admins.map((admin) => {
                  return (
                     <UserChip user={admin}>
                        {response?.isAdmin && (
                           <DeleteIcon color={"primary"} onClick={() => remotePromoter.removeAdmin(admin.id)} />
                        )}
                     </UserChip>
                  );
               })}
            </Box>
         </AccordionDetails>
      </Accordion>
   );
}
