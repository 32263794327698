import React from "react";
import Header from "./header/Header";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import useCurrentUser, { useCurrentUserListener } from "./auth/useCurrentUser";
import VerifiedEmailChecker from "./userFeatures/VerifiedEmailChecker";
import { BODY_HEIGHT_IN_CALC, HEADER_HEIGHT_IN_PX } from "./UI_CONST";
import Messages from "./messagesComponent/Messages";

const frameRootSx: SxProps = {
   width: "100vw",
   height: "100vh",
   overflow: "hidden",
};

export const bodyRootSx: SxProps = {
   position: "absolute",
   left: 0,
   top: `${HEADER_HEIGHT_IN_PX}px`,
   width: "100vw",
   height: BODY_HEIGHT_IN_CALC,
};

export default function Frame(props: React.PropsWithChildren<{}>) {
   useCurrentUserListener();
   const user = useCurrentUser();
   return (
      <Box id={"frameRoot"} sx={frameRootSx}>
         <Header />
         {/*<InteractiveTilesComponent />*/}
         <Box id={"bodyRoot"} sx={bodyRootSx}>
            {user.setupComplete && props.children}
         </Box>
         <VerifiedEmailChecker />
         <Messages />
      </Box>
   );
}
