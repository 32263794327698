import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Fade } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useRemoteTournament from "../useRemoteTournament";
import TournamentCustomFlowDialog from "../creationPage/TournamentCustomFlowDialog";
import { Maybe } from "../../TYPE";
import Footer from "../../Footer";
import CreateTestTournamentDialog from "../creationPage/CreateTestTournamentDialog";
import TournamentCreationDialog from "../creationPage/TournamentCreationDialog";
import { CreationFlowType } from "../creationPage/CustomOrTemplateOptions";
import TournamentTemplateFlowDialog from "../creationPage/TournamentTemplateFlowDialog";
import { SxProps } from "@mui/system";
import WelcomeContent from "./WelcomeContent";
import { ALL_TOURNAMENTS_PATH, HOME_PATH } from "../../index";
import TournamentsContent from "./TournamentsContent";
import { MAIN_PAGE_TOURNAMENTS, MAIN_PAGE_WELCOME_SCREEN } from "../../UI_CONST";

const rootSx: SxProps = {
   boxSizing: "border-box",
   position: "absolute",
   left: "0px",
   top: "0px",
   width: "100%",
   height: "100%",
};

const bgImgSx: SxProps = {
   position: "absolute",
   left: 0,
   top: 0,
   width: "100%",
   height: "100%",
   objectFit: "cover",
};

const contentWrapperSx: SxProps = {
   position: "absolute",
   left: 0,
   top: 0,
   width: "100%",
   height: "100%",
   display: "flex",
   flexDirection: "column",
};

interface ITournamentsViewPage {}

const TournamentsListPage: React.FC<ITournamentsViewPage> = () => {
   const location = useLocation();
   const nav = useNavigate();
   const [flowType, setFlowType] = useState<Maybe<CreationFlowType>>();
   const remoteTournament = useRemoteTournament();
   const showWelcomeContent = useMemo(() => !location.pathname.includes(ALL_TOURNAMENTS_PATH), [location.pathname]);
   const [openCreateDialog, setOpenCreateDialog] = useState(false);
   const [openCreateTestTournamentDialog, setOpenCreateTestTournamentDialog] = useState(false);

   useEffect(() => {
      const mainPageContent = localStorage.getItem("mainPageContent") || MAIN_PAGE_WELCOME_SCREEN;
      if (mainPageContent === MAIN_PAGE_WELCOME_SCREEN && location.pathname !== HOME_PATH) {
         nav(HOME_PATH);
      }
      if (mainPageContent === MAIN_PAGE_TOURNAMENTS && location.pathname !== ALL_TOURNAMENTS_PATH) {
         nav(ALL_TOURNAMENTS_PATH);
      }
   }, [location.pathname, nav]);

   const handleCreateButtonClick = useCallback(() => {
      setOpenCreateDialog(true);
   }, []);

   return (
      <Box sx={rootSx}>
         <Fade in={showWelcomeContent} timeout={1000}>
            <Box component="img" sx={bgImgSx} src={"/mainPageBg1.jpg"} />
         </Fade>
         <Fade in={!showWelcomeContent} timeout={1000}>
            <Box component="img" sx={bgImgSx} src={"/mainPageBg3.jpg"} />
         </Fade>
         <Box sx={contentWrapperSx}>
            {showWelcomeContent ? (
               <>
                  <WelcomeContent onCreateClicked={handleCreateButtonClick} />
                  <Footer />
               </>
            ) : (
               <TournamentsContent handleCreateButtonClick={handleCreateButtonClick} />
            )}
         </Box>
         <TournamentCustomFlowDialog
            open={flowType === "CUSTOM"}
            remoteTournament={remoteTournament}
            onClose={() => setFlowType(null)}
         />
         <TournamentTemplateFlowDialog open={flowType === "TEMPLATE"} onClose={() => setFlowType(null)} />
         <TournamentCreationDialog
            open={openCreateDialog}
            close={() => setOpenCreateDialog(false)}
            onSelectFlowType={setFlowType}
         />
         <CreateTestTournamentDialog
            open={openCreateTestTournamentDialog}
            onClose={() => setOpenCreateTestTournamentDialog(false)}
         />
      </Box>
   );
};

export default TournamentsListPage;
