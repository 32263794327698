import React, { useEffect, useMemo, useState } from "react";
import { Box, Paper, TablePagination, TextField, Typography, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTournamentApi } from "../rest.client/useTournamentApi";
import useCurrentUser from "../auth/useCurrentUser";
import { Maybe } from "../TYPE";
import TournamentTile from "../pageTournament/listPage/TournamentTile";
import TablePaginationActions from "../gameFeatures/myGamesPage/TablePagingActions";

interface ITournamentsViewPage {}

const TournamentsViewPage: React.FC<ITournamentsViewPage> = () => {
   const theme = useTheme();
   const currentUser = useCurrentUser();
   const {
      searchTournamentsByUserIdAndText: { call: searchTournaments, responseData, loading },
   } = useTournamentApi();
   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const allTournaments = responseData?._embedded.tournaments || [];
   const pageData = responseData?.page;
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(25);
   const [searchText, setSearchText] = useState<Maybe<string>>(null);

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   useEffect(() => {
      if (currentUser.user) {
         searchTournaments({
            queryParams: {
               page: page,
               size: rowsPerPage,
               searchText: searchText,
               projection: "summary",
               sort: "startDate,desc",
               userId: currentUser.user?.uid,
            },
         });
      }
   }, [currentUser.user, page, rowsPerPage, searchText, searchTournaments]);

   const tournamentTiles = useMemo(() => {
      if (loading) return <CircularProgress size={80} />;
      if (allTournaments.length > 0) {
         return allTournaments.map((tournamentSummary) => (
            <TournamentTile key={tournamentSummary.id} tournamentSummary={tournamentSummary} />
         ));
      } else {
         return (
            <Paper
               elevation={4}
               sx={{
                  padding: theme.spacing(4),
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(1),
               }}
            >
               <Typography variant={"h6"}>{"No tournaments match your filters!"}</Typography>
            </Paper>
         );
      }
   }, [allTournaments, loading, theme]);

   return (
      <Box
         id={"tournamentsViewPageRoot"}
         style={{
            width: "100%",
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexWrap: "wrap",
            boxSizing: "border-box",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
            alignContent: "flex-start",
            rowGap: "5%",
         }}
      >
         <Paper
            sx={{
               width: "100%",
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               flexWrap: "wrap",
               alignItems: "center",
               padding: theme.spacing(1),
            }}
         >
            <TextField
               size={"small"}
               variant={"outlined"}
               defaultValue={""}
               label={"Search"}
               sx={{ minWidth: "200px", flexGrow: 1 }}
               onChange={(ev) => {
                  if (searchActiveTimeout != null) {
                     clearTimeout(searchActiveTimeout);
                     setSearchActiveTimeout(null);
                  }
                  const timeOutId = window.setTimeout(() => {
                     setSearchText(ev.target.value);
                  }, 500);
                  setSearchActiveTimeout(timeOutId);
               }}
            />
            {pageData && (
               <Box>
                  <TablePagination
                     component={"div"}
                     rowsPerPageOptions={[10, 25, 50, 100]}
                     count={pageData.totalElements}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     SelectProps={{
                        inputProps: {
                           "aria-label": "rows per page",
                        },
                     }}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                     ActionsComponent={TablePaginationActions}
                  />
               </Box>
            )}
         </Paper>
         <Box
            sx={{
               display: "flex",
               flexWrap: "wrap",
               alignItems: "flex-start",
               justifyContent: "space-evenly",
               gap: theme.spacing(4),
               width: "100%",
            }}
         >
            {tournamentTiles}
         </Box>
      </Box>
   );
};

export default TournamentsViewPage;
