import React, { ReactNode } from "react";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { UserSummary } from "../rest.client/useApplicationApi";
import { Consumer } from "../utils";

export default function UserChip({
   user,
   children,
   onClick,
}: {
   user: UserSummary;
   children?: ReactNode;
   onClick?: Consumer<UserSummary>;
}) {
   const theme = useTheme();

   return (
      <Chip
         size={"medium"}
         onClick={() => onClick && onClick(user)}
         label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <Box sx={{ display: "flex" }}></Box>
               <Typography maxWidth={"250px"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>
                  <span
                     style={{
                        marginLeft: theme.spacing(1),
                        fontWeight: "normal",
                     }}
                  >
                     {user.name}
                     <span
                        style={{
                           color: theme.palette.text.secondary,
                           fontStyle: "italic",
                        }}
                     >
                        /{user.shortTag}
                     </span>
                  </span>
               </Typography>
               {children}
            </Box>
         }
         variant="outlined"
      />
   );
}
