import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from "@mui/material";
import { RemoteTournament } from "../useRemoteTournament";
import { API_BASE_URL } from "../../rest.client/useApplicationApi";

const QrCodeDialog: React.FC<{
   open: boolean;
   remoteTournament: RemoteTournament;
   closeHandler: () => void;
}> = ({ open, closeHandler, remoteTournament }) => {
   const theme = useTheme();
   const qrUrl = API_BASE_URL + "tournaments/" + remoteTournament.id + "/qrCode";

   return (
      <Dialog
         id={"tournamentRegistrationDrawer"}
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         maxWidth={"sm"}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               closeHandler();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">QR code</DialogTitle>
         <DialogContent>
            {open && remoteTournament.id && <img src={qrUrl} alt={"Qr code for tournament"} />}
         </DialogContent>
         <DialogActions>
            <Button
               variant={"contained"}
               onClick={async () => {
                  const url = qrUrl; // Replace with your file URL
                  const fileName = remoteTournament.tournament?.name + "-QR-code.jpg"; // Desired name for the downloaded file

                  try {
                     const response = await fetch(url);
                     const blob = await response.blob();

                     const link = document.createElement("a");
                     const objectUrl = URL.createObjectURL(blob);

                     link.href = objectUrl;
                     link.download = fileName;
                     document.body.appendChild(link);
                     link.click();

                     // Clean up
                     document.body.removeChild(link);
                     URL.revokeObjectURL(objectUrl);
                  } catch (error) {
                     console.error("Download failed", error);
                  }
               }}
            >
               Download
            </Button>
            <Button variant={"outlined"} onClick={closeHandler}>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default QrCodeDialog;
