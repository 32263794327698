import React, { useState } from "react";
import {
   Alert,
   Avatar,
   Button,
   FormLabel,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   useTheme,
} from "@mui/material";
import { Consumer, empty } from "../../utils";
import { Maybe } from "../../TYPE";
import { ComponentMode } from "../../UI_CONST";
import UserSelectorAutocomplete from "../../gameFeatures/UserSelectorAutocomplete";
import { UserSummary } from "../../rest.client/useApplicationApi";
import { Box } from "@mui/system";
import HelpIcon from "../../common/HelpIcon";

export default function OrganiserEditBox({
   removeOrganiser,
   addOrganiser,
   organisers,
}: {
   addOrganiser: Consumer<UserSummary>;
   removeOrganiser: Consumer<UserSummary>;
   organisers: UserSummary[];
}) {
   const theme = useTheme();
   const [selectedOrganiser, setSelectedOrganiser] = useState<Maybe<UserSummary>>();

   return (
      <Box sx={{ minWidth: 200, width: "100%" }}>
         <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
            <FormLabel>Tournament organisers</FormLabel>
            <HelpIcon
               message={
                  "Here you can assign the role of organiser to other system users. " +
                  "This will give them full control over the tournament but they will not be abel to remove the tournament owner."
               }
            />
         </Box>
         <Box sx={{ display: "flex" }}>
            <UserSelectorAutocomplete
               mode={ComponentMode.EDIT}
               playerColor={theme.palette.text.primary}
               playerIndex={1}
               hideWinnerSelector={true}
               playerSummary={selectedOrganiser}
               selectPlayerHandler={setSelectedOrganiser}
               hideAnonymousSelector={true}
               otherPlayers={organisers}
               setWinner={empty}
               wonGame={false}
               showStatsButton={false}
               labelText={"Search for organiser"}
            />
            <Button
               onClick={async () => {
                  selectedOrganiser && addOrganiser(selectedOrganiser);
                  setSelectedOrganiser(undefined);
               }}
               disabled={!selectedOrganiser}
            >
               Add
            </Button>
         </Box>
         <List
            sx={{
               width: "100%",
               bgcolor: "background.paper",
               maxHeight: "200px",
               overflow: "auto",
            }}
         >
            {organisers.map((user) => {
               return (
                  <ListItem alignItems="flex-start">
                     <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={"null"} />
                     </ListItemAvatar>
                     <ListItemText primary={user.name} />
                     <Button
                        onClick={async () => {
                           removeOrganiser(user);
                        }}
                     >
                        Remove
                     </Button>
                  </ListItem>
               );
            })}
            {organisers.length === 0 ? <Alert severity={"info"}>No organisers added yet!</Alert> : ""}
         </List>
      </Box>
   );
}
