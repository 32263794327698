import { Box, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";

const smallScreenRootSx: SxProps = {
   width: "100%",
   backdropFilter: "blur(10px) sepia(1)",
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
   textShadow: "0 0 1px black, 0 0 2px black, 0 0 3px black",
   background: "linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(0,0,0,0.8))",
   position: "absolute",
   left: 0,
   bottom: 0,
   transform: "translateY(100%)",
};

const rootSx: SxProps = {
   width: "100%",
   backdropFilter: "blur(10px) sepia(1)",
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
   textShadow: "0 0 1px black, 0 0 2px black, 0 0 3px black",
   background: "linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(0,0,0,0.8))",
};

const Footer: React.FC = () => {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   return (
      <Box id={"footerRoot"} sx={smallScreen ? smallScreenRootSx : rootSx}>
         <Box
            sx={{
               padding: theme.spacing(1),
               // paddingLeft: theme.spacing(4),
               // paddingRight: theme.spacing(4),
               // paddingTop: theme.spacing(2),
               // paddingBottom: theme.spacing(2),
            }}
         >
            <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
               <Typography variant={"caption"}>
                  Parts of Brainstorm TMS are unofficial Fan Content permitted under the Wizards of the Coast Fan
                  Content Policy. The literal and graphical information presented on this site about Magic: The
                  Gathering, including card images, mana symbols, and Oracle text, is copyright Wizards of the Coast,
                  LLC, a subsidiary of Hasbro, Inc. Brainstorm TMS is not produced by or endorsed by Wizards of the
                  Coast.
               </Typography>
               <Typography variant={"caption"}>
                  <span>Card data provided by </span>
                  <Link href="https://mtgjson.com/" target="_blank" rel="nofollow noopener noreferrer">
                     MTGJson.
                  </Link>
                  <span> Card images provided by </span>
                  <Link href="https://www.scryfall.com" target="_blank" rel="nofollow noopener noreferrer">
                     Scryfall.
                  </Link>
                  <span>
                     {" "}
                     For more information see the <Link href={"/tos/"}>Terms of service.</Link>
                  </span>
                  <span> Join us on </span>
                  <Link target={"_blank"} rel="noopener" href={"https://discord.gg/DAQ6SJaa6M"}>
                     Discord.
                  </Link>
               </Typography>
            </Box>
         </Box>
      </Box>
   );
};

export default Footer;
