import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
   interface Palette {
      interactiveTilesColor: string;
      tilesGradiant1: string;
      tilesGradiant2: string;
      tilesGradiant3: string;
      boxShadowColor: string;
      borderColor: string;
      mainHoverColor: string;
      headerButtonColor: string;
      textShadow1: string;
      deckViewerBackgroundColor: string;
      deckViewerTextColor: string;
      deckViewerTextShadowColor: string;
      deckViewerCardRowBottomShadowColor: string;
      fadedBackground: string;
   }

   interface PaletteOptions {
      interactiveTilesColor: string;
      tilesGradiant1: string;
      tilesGradiant2: string;
      tilesGradiant3: string;
      boxShadowColor: string;
      borderColor: string;
      mainHoverColor: string;
      headerButtonColor: string;
      deckViewerBackgroundColor: string;
      deckViewerTextColor: string;
      deckViewerTextShadowColor: string;
      deckViewerCardRowBottomShadowColor: string;
      fadedBackground: string;
   }
}

const fonts = [
   "Roboto",
   '"Helvetica Neue"',
   "Arial",
   "sans-serif",
   '"Apple Color Emoji"',
   '"Segoe UI Emoji"',
   '"Segoe UI Symbol"',
].join(",");

const components = {
   MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Neon';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Neon'), local('Neon'), url(/Neon_Energy_x.ttf) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
   },
};

export const lightTheme = createTheme({
   components: components,
   typography: {
      fontFamily: fonts,
      body2: {
         fontSize: "0.8rem",
         color: "rgba(0,0,0,0.6)",
      },
      button: {
         textTransform: "none",
      },
   },
   palette: {
      mode: "light",
      primary: {
         main: "rgb(184,73,0)",
         light: "rgb(255,93,0)",
      },
      secondary: {
         main: "#308ca8",
      },
      background: {
         default: "#ffffff",
         paper: "#e7e7e7",
      },
      text: {},
      interactiveTilesColor: "rgba(230,230,230,1)",
      tilesGradiant1: "rgb(230,240,240)",
      tilesGradiant2: "rgb(230,233,230)",
      tilesGradiant3: "rgb(220,210,230)",
      boxShadowColor: "rgba(0,0,0,1)",
      borderColor: "rgba(0, 0, 0, 0.2)",
      mainHoverColor: "rgba(0, 0, 0, 0.4)",
      headerButtonColor: "rgb(0,0,0)",
      deckViewerBackgroundColor: `linear-gradient(330deg, rgb(140,150,150) 0%, rgb(210,210,210) 60%, rgb(170,160,155) 100%)`,
      deckViewerTextColor: "rgb(28,12,0)",
      deckViewerTextShadowColor: "rgb(148,75,21)",
      deckViewerCardRowBottomShadowColor: "rgb(0,0,0)",
      fadedBackground: "rgb(255,255,255,.4)",
   },
});

export const darkTheme = createTheme({
   typography: {
      fontFamily: fonts,
      button: {
         textTransform: "none",
      },
      body2: {
         fontSize: "0.8rem",
         color: "rgba(255,255,255,0.6)",
      },
   },
   components: {
      ...components,
      MuiMenu: {
         styleOverrides: {
            root: {
               ".MuiMenu-paper": {
                  backgroundColor: "rgba(0,0,0,0.8)",
                  boxShadow: `0 0 5px 1px rgba(0,0,0,0.5)`,
                  backdropFilter: "blur(10px)",
               },
            },
         },
      },
   },
   palette: {
      mode: "dark",
      primary: {
         main: "rgb(184,73,0)",
      },
      secondary: {
         main: "#308ca8",
      },
      background: {},
      text: {},
      interactiveTilesColor: "rgb(46,46,46)",
      tilesGradiant1: "rgb(37,53,55)",
      tilesGradiant2: "rgb(67,54,47)",
      tilesGradiant3: "rgb(35,29,42)",
      boxShadowColor: "rgb(84,84,84)",
      borderColor: "rgba(255,255,255,0.2)",
      mainHoverColor: "rgba(255, 255, 255, 0.4)",
      headerButtonColor: "rgb(255,255,255)",
      deckViewerBackgroundColor: `linear-gradient(150deg, rgb(37,53,55) 0%, rgb(67,54,47) 60%, rgb(35,29,42) 100%)`,
      deckViewerTextColor: "rgb(255,255,255)",
      deckViewerTextShadowColor: "#b95700",
      deckViewerCardRowBottomShadowColor: "rgb(0,0,0)",
      fadedBackground: "rgb(0,0,0,.75)",
   },
});
