import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import { RoundFullResponse } from "../../rest.client/useStagesApi";

export default function RoundTimerCountdown({
   setTimerSeconds,
   round,
   showMinsOnly = false,
   size = 70,
   fontSize = 17.5,
   minsOnlyFontSize = 35,
}: {
   setTimerSeconds?: (seconds: number) => void;
   round: RoundFullResponse;
   size?: number | string;
   fontSize?: number | string;
   minsOnlyFontSize?: number | string;
   showMinsOnly?: boolean;
}) {
   const [fullSecondsLeft, setFullSecondsLeft] = useState<number>(0);
   const [isPositiveCountDown, setIsPositiveCountDown] = useState(true);
   const secsLeft = Math.abs(fullSecondsLeft % 60);
   const minsLeft = isPositiveCountDown ? Math.floor(fullSecondsLeft / 60) : `-${Math.floor(fullSecondsLeft / 59.9)}`;
   const stringToShow = useMemo(
      () => (showMinsOnly ? `${minsLeft}` : `${minsLeft} : ${secsLeft}`),
      [minsLeft, secsLeft, showMinsOnly]
   );

   useEffect(() => {
      setTimerSeconds && setTimerSeconds(fullSecondsLeft);
   }, [fullSecondsLeft, setTimerSeconds]);

   useEffect(() => {
      const update = () => {
         if (round.roundEndDate) {
            const positive = dayjs(new Date(round.roundEndDate)).diff(new Date(), "seconds");
            if (positive >= 0) setFullSecondsLeft(positive);
            else {
               if (isPositiveCountDown) setIsPositiveCountDown(false);
               const negative = dayjs(new Date()).diff(new Date(round.roundEndDate), "seconds");
               setFullSecondsLeft(negative);
            }
         } else setFullSecondsLeft(0);
      };
      update();
      const interval = setInterval(() => {
         update();
      }, 1000);

      return () => {
         clearInterval(interval);
      };
   }, [isPositiveCountDown, round.roundEndDate]);
   return (
      <Box display="flex" justifyContent="center" alignItems="center">
         <CircularProgress
            color={fullSecondsLeft < 5 * 60 ? "error" : fullSecondsLeft < 15 * 60 ? "warning" : "success"}
            variant="determinate"
            size={size}
            value={secsLeft === 0 ? 100 : (secsLeft / 60) * 100}
         ></CircularProgress>
         <Typography position="absolute" fontWeight={"bold"} fontSize={showMinsOnly ? minsOnlyFontSize : fontSize}>
            {stringToShow}
         </Typography>
      </Box>
   );
}
