import React, { FC, useEffect, useMemo, useState } from "react";
import { SxProps } from "@mui/system";
import {
   Box,
   Button,
   Checkbox,
   FormControlLabel,
   FormGroup,
   Slide,
   TablePagination,
   TextField,
   Theme,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH } from "../../UI_CONST";
import useCurrentUser from "../../auth/useCurrentUser";
import useReload from "../../header/useReload";
import { PROMOTER } from "../../common/reloadKeys";
import { usePromotersApi } from "../../rest.client/usePromotersApi";
import { Maybe } from "../../TYPE";
import TablePaginationActions from "../../gameFeatures/myGamesPage/TablePagingActions";
import CreateOrEditPromoterDialog from "../CreateOrEditPromoterDialog";
import CircularProgress from "@mui/material/CircularProgress";
import PromoterTile from "./PromoterTile";

const rootSx: SxProps = {
   position: "absolute",
   display: "flex",
   flexDirection: "column",
   alignContent: "center",
   alignItems: "center",
   width: { xs: "100%", sm: `calc(100% - ${PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH}px)` },
   left: {
      xs: 0,
      sm: `${PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH}px`,
   },
   height: "100%",
   backgroundImage:
      "linear-gradient(to top, black 5%, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0.8) 20%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.5) 50%, transparent 100%)",
};

const controlPanelSx: SxProps<Theme> = {
   position: "relative",
   width: "100%",
   display: "flex",
   flexDirection: "column",
   padding: (theme) => theme.spacing(1),
   borderRadius: "5px",
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
   backdropFilter: "blur(3px) sepia(1)",
};

const controlPanelRowSx: SxProps = {
   display: "flex",
   width: "100%",
   alignItems: "center",
   justifyContent: "space-between",
   gap: "10px",
};

const tilesWrapperSx: SxProps<Theme> = {
   display: "flex",
   flexWrap: "wrap",
   alignItems: "flex-start",
   alignContent: "flex-start",
   justifyContent: "flex-start",
   gap: { xs: `10px`, sm: `20px` },
   width: "100%",
   height: { xs: `calc(100% - 162px)`, sm: `calc(100% - 114px)` },
   padding: { xs: `10px`, sm: `20px` },
   overflow: "auto",
};

const loaderWrapperSx: SxProps = {
   width: "100%",
   height: "100%",
   display: "flex",
   flexDirection: "column",
};

interface ITilesContainer {
   show: boolean;
}

const TilesContainer: FC<ITilesContainer> = ({ show }) => {
   const theme = useTheme();
   const currentUser = useCurrentUser();
   const { reload } = useReload(PROMOTER);
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const {
      findPromotersByUserIdAndText: { call: findPromoters, responseData, loading },
   } = usePromotersApi();
   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const allPromoters = useMemo(() => responseData?._embedded.promoters || [], [responseData?._embedded.promoters]);
   const pageData = responseData?.page;
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(25);
   const [searchText, setSearchText] = useState<Maybe<string>>(null);
   const [filterMyPromoters, setFilterMyPromoters] = useState(false);
   const [openCreatePromoter, setOpenCreatePromoter] = useState(false);

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   useEffect(() => {
      findPromoters({
         queryParams: {
            page: page,
            size: rowsPerPage,
            searchText: searchText,
            sort: "createDate,desc",
            userId: filterMyPromoters ? currentUser.user?.uid : null,
         },
      });
   }, [currentUser.user, filterMyPromoters, findPromoters, page, rowsPerPage, searchText, reload]);

   const promoterTiles = useMemo(() => {
      if (loading)
         return (
            <Box sx={loaderWrapperSx}>
               <CircularProgress sx={{ margin: "auto" }} size={80} />
            </Box>
         );
      if (allPromoters.length > 0) {
         return allPromoters.map((tournamentSummary) => (
            <PromoterTile key={tournamentSummary.id} promoter={tournamentSummary} />
         ));
      }
      return (
         <Box sx={loaderWrapperSx}>
            <Typography sx={{ margin: "auto", marginBottom: 0 }} variant={"h4"}>
               {"No Promoters found!"}
            </Typography>
            {currentUser.loggedIn && (
               <Button
                  sx={{ margin: "auto", marginTop: "10px" }}
                  variant={"contained"}
                  size={"large"}
                  onClick={() => setOpenCreatePromoter(true)}
               >
                  Create one!
               </Button>
            )}
         </Box>
      );
   }, [allPromoters, currentUser.loggedIn, loading]);

   const controlPanel = useMemo(
      () =>
         currentUser.loggedIn ? (
            <Box sx={controlPanelSx}>
               <Box sx={controlPanelRowSx}>
                  <FormGroup>
                     <FormControlLabel
                        labelPlacement={smallScreen ? "bottom" : "end"}
                        control={
                           <Checkbox
                              checked={filterMyPromoters}
                              onChange={() => {
                                 setFilterMyPromoters(!filterMyPromoters);
                              }}
                           />
                        }
                        label="My promoter accounts"
                     />
                  </FormGroup>
                  <TextField
                     size={"small"}
                     variant={"outlined"}
                     defaultValue={""}
                     label={"Search"}
                     sx={{ flexGrow: 1 }}
                     onChange={(ev) => {
                        if (searchActiveTimeout != null) {
                           clearTimeout(searchActiveTimeout);
                           setSearchActiveTimeout(null);
                        }
                        const timeOutId = window.setTimeout(() => {
                           setSearchText(ev.target.value);
                        }, 500);
                        setSearchActiveTimeout(timeOutId);
                     }}
                  />
                  <Button variant={"contained"} size={"small"} onClick={() => setOpenCreatePromoter(true)}>
                     Create
                  </Button>
               </Box>
               <TablePagination
                  sx={{ margin: "auto" }}
                  component={"div"}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={pageData ? pageData.totalElements : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                     inputProps: {
                        "aria-label": "rows per page",
                     },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
               />
            </Box>
         ) : (
            <></>
         ),
      [currentUser.loggedIn, filterMyPromoters, page, pageData, rowsPerPage, searchActiveTimeout, smallScreen]
   );

   return (
      <Slide in={show} direction={"up"}>
         <Box sx={rootSx}>
            <Box sx={tilesWrapperSx}>{promoterTiles}</Box>
            {controlPanel}
            {openCreatePromoter && (
               <CreateOrEditPromoterDialog open={openCreatePromoter} close={() => setOpenCreatePromoter(false)} />
            )}
         </Box>
      </Slide>
   );
};

export default TilesContainer;
