import { useCallback, useMemo } from "react";
import { RegistrationMode, TournamentSummary, useTournamentApi } from "../../rest.client/useTournamentApi";
import { useStageApi } from "../../rest.client/useStagesApi";
import { Maybe } from "../../TYPE";

export interface TemplateCreator {
   loading: boolean;
   createLeague: (
      name: string,
      location: string,
      startDate: Date,
      promoterId: Maybe<string>,
      registrationMode: RegistrationMode,
      password?: string
   ) => Promise<TournamentSummary>;
   createSwissCut: (
      name: string,
      location: string,
      startDate: Date,
      promoterId: Maybe<string>,
      playerCount: number,
      cut: number
   ) => Promise<TournamentSummary>;
   createSwissOnly: (
      name: string,
      location: string,
      startDate: Date,
      promoterId: Maybe<string>,
      playerCount: number
   ) => Promise<TournamentSummary>;
}

export default function useTemplateCreator(): TemplateCreator {
   const {
      createTournament: { call: createTournament },
      loading: loadingTournament,
   } = useTournamentApi();
   const {
      createStage: { call: createStage },
      loading: loadingStage,
   } = useStageApi();

   const createSwissCut = useCallback(
      async (
         name: string,
         location: string,
         startDate: Date,
         promoterId: Maybe<string>,
         playerCount: number,
         cut: number
      ) => {
         const tournamentSummary = await createTournament({
            body: {
               description: "",
               startDate: startDate,
               maxPlayerCount: playerCount,
               registrationMode: "OPEN",
               isPublic: false,
               autoAcceptRegistrations: false,
               makeDecklistsVisible: false,
               promoterId,
               location: location,
               name: name,
               tournamentOrganisers: [],
            },
         });
         try {
            await createStage({
               body: {
                  tournamentId: tournamentSummary.id,
                  name: "Swiss",
                  autoQualifyEnabled: false,
                  podOrderingStrategy: "RANDOM",
                  autoPairStrategy: "STANDINGS_BASED_GROUPED",
                  playerCountLimit: playerCount,
                  swissSettings: {
                     winPoints: 5,
                     lossPoints: 0,
                     drawPoints: 1,
                     byePoints: 5,
                     secondPlacePoints: 0,
                     thirdPlacePoints: 0,
                     fourthPlacePoints: 0,
                     tiebreakerSystem: "MAMTR_3_1",
                     byeAllocationStrategy: "MSTR_REGULAR_REL",
                     matchWinPercentageFloor: 0.2,
                  },
               },
            });
            await createStage({
               body: {
                  tournamentId: tournamentSummary.id,
                  name: "Cut",
                  autoQualifyEnabled: false,
                  podOrderingStrategy: "PREVIOUS_STAGE_STANDINGS",
                  autoPairStrategy: "MAMTR_ELIMINATION_PAIRING",
                  playerCountLimit: cut,
                  singleEliminationSettings: {},
               },
            });
         } catch (e) {
            console.error(e);
         }
         return tournamentSummary;
      },
      [createStage, createTournament]
   );

   const createSwissOnly = useCallback(
      async (name: string, location: string, startDate: Date, promoterId: Maybe<string>, playerCount: number) => {
         const tournamentSummary = await createTournament({
            body: {
               description: "",
               startDate: startDate,
               maxPlayerCount: playerCount,
               registrationMode: "OPEN",
               isPublic: false,
               promoterId,
               autoAcceptRegistrations: false,
               makeDecklistsVisible: false,
               location: location,
               name: name,
               tournamentOrganisers: [],
            },
         });
         try {
            await createStage({
               body: {
                  tournamentId: tournamentSummary.id,
                  name: "Swiss",
                  autoQualifyEnabled: false,
                  podOrderingStrategy: "RANDOM",
                  autoPairStrategy: "STANDINGS_BASED_GROUPED",
                  playerCountLimit: playerCount,
                  swissSettings: {
                     winPoints: 5,
                     lossPoints: 0,
                     drawPoints: 1,
                     byePoints: 5,
                     secondPlacePoints: 0,
                     thirdPlacePoints: 0,
                     fourthPlacePoints: 0,
                     tiebreakerSystem: "MAMTR_3_1",
                     byeAllocationStrategy: "MSTR_REGULAR_REL",
                     matchWinPercentageFloor: 0.2,
                  },
               },
            });
         } catch (e) {
            console.error(e);
         }
         return tournamentSummary;
      },
      [createStage, createTournament]
   );

   const createLeague = useCallback(
      async (
         name: string,
         location: string,
         startDate: Date,
         promoterId: Maybe<string>,
         registrationMode: RegistrationMode,
         password?: string
      ) => {
         const tournamentSummary = await createTournament({
            body: {
               description: "",
               startDate: startDate,
               maxPlayerCount: 128,
               registrationMode: registrationMode,
               isPublic: false,
               autoAcceptRegistrations: true,
               password,
               promoterId,
               makeDecklistsVisible: false,
               location: location,
               name: name,
               tournamentOrganisers: [],
            },
         });
         try {
            await createStage({
               body: {
                  tournamentId: tournamentSummary.id,
                  name: "League",
                  autoQualifyEnabled: true,
                  podOrderingStrategy: "RANDOM",
                  autoPairStrategy: "RANDOM",
                  playerCountLimit: 128,
                  unstructuredLeagueSettings: {
                     winPoints: 65,
                     lossPoints: -20,
                     drawPoints: 0,
                     secondPlacePoints: 0,
                     thirdPlacePoints: 0,
                     fourthPlacePoints: 0,
                     tiebreakerSystem: "MAMTR_3_1",
                     allowPlayerSubmissions: true,
                     matchWinPercentageFloor: 0.2,
                  },
               },
            });
         } catch (e) {
            console.error(e);
         }
         return tournamentSummary;
      },
      [createStage, createTournament]
   );

   return useMemo(
      () => ({
         createSwissCut,
         createLeague,
         createSwissOnly,
         loading: loadingStage || loadingTournament,
      }),
      [createLeague, createSwissCut, createSwissOnly, loadingStage, loadingTournament]
   );
}
