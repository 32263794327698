import React, { FC } from "react";
import { Box, Button, Slide, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Procedure } from "../../utils";

const bannerRootSx: SxProps = {
   position: "absolute",
   left: 0,
   top: 0,
   display: "flex",
   width: "100%",
   height: "100%",
};

const bannerContentSx: SxProps = {
   position: "absolute",
   right: 0,
   top: 0,
   display: "flex",
   flexDirection: "column",
   width: { xs: "100%", sm: "55%" },
   // width: "100%",
   height: "100%",
   // backdropFilter: "blur(20px)",
   // mask: "linear-gradient(to left, black, black, transparent)",
   backgroundImage: "linear-gradient(to right, transparent 0%, rgba(0,0,0,0.5) 20%, black 100%)",
   gap: "10px",
   padding: "10px 10% 10px 10px",
   boxSizing: "border-box",
   alignItems: "center",
   justifyContent: "center",
};

const btnSx: SxProps = {
   fontSize: "1.2em",
};

const textContentSx: SxProps = {
   maxWidth: "90%",
   borderRadius: "5px",
   backdropFilter: "blur(5px) sepia(0.7)",
   boxSizing: "border-box",
   padding: "10px",
   // color: (theme) => theme.palette.primary.light,
   background: "linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0.5), rgba(0,0,0,0.8))",
   textShadow: "0 0 1px black, 0 0 2px black, 0 0 3px black, 0 0 4px black, 0 0 5px black",
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
};

const titleSx: SxProps = {
   fontSize: { xs: "1em", sm: "1.5em", md: "2em" },
   fontFamily: "Neon",
   fontWeight: 400,
   fontStyle: "italic",
   pointerEvents: "none",
   textShadow: "0 0 1px black, 0 0 2px black, 0 0 3px black, 0 0 4px black, 0 0 5px black, 0 0 6px orange",
};

const btnWrapperSx: SxProps<Theme> = {
   display: "flex",
   flexDirection: { xs: "column", md: "row" },
   alignItems: "center",
   gap: (theme) => theme.spacing(1),
};

interface IPromoterBanner {
   show: boolean;
   toList: Procedure;
   onClickCreate: Procedure;
}

const PromoterBanner: FC<IPromoterBanner> = ({ show, toList, onClickCreate }) => {
   return (
      <Slide direction={"left"} in={show}>
         <Box sx={bannerRootSx}>
            <Box id={"bannerContent"} sx={bannerContentSx}>
               <Typography sx={titleSx}>{"Promoter accounts"}</Typography>
               <Typography sx={textContentSx}>
                  {"What is a promoter account? A promoter account provides a place to manage your team and group all your tournaments together under one roof." +
                     " If you are running a store, hosting large events or just managing a small scale tournament series a promoter account is what you need!" +
                     " Click below to get started!"}
               </Typography>
               <Box sx={btnWrapperSx}>
                  <Button size={"large"} sx={btnSx} variant={"contained"} onClick={onClickCreate}>
                     {"Create promoter account"}
                  </Button>
                  <Button size={"large"} sx={btnSx} variant={"outlined"} onClick={toList}>
                     {"View promoter accounts"}
                  </Button>
               </Box>
            </Box>
         </Box>
      </Slide>
   );
};

export default PromoterBanner;
