import React, { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { PlayerSummary, useApplicationApi, UserSummary } from "../../rest.client/useApplicationApi";
import { Maybe, TPlayerIndex } from "../../TYPE";
import { SxProps } from "@mui/system";
import CardPlaceHolderComponent from "./CardPlaceHolderComponent";
import {
   ComponentMode,
   PLAYER1_TEXT_COLOR,
   PLAYER2_TEXT_COLOR,
   PLAYER3_TEXT_COLOR,
   PLAYER4_TEXT_COLOR,
} from "../../UI_CONST";
import { CommanderPair } from "../../cardFeatures/CardTypes";
import UserSelectorAutocomplete from "../UserSelectorAutocomplete";
import { Consumer, Procedure } from "../../utils";
import PlayerStatusComponent from "./PlayerStatusComponent";
import { useSetPlayerAndDefaultCommander } from "../../customHooks/customHooks";

const coloredBackgroundBasicSx: SxProps = {
   position: "absolute",
   transition: "opacity 0.5s",
   opacity: 0,
};

const playerAndCommanderComponentContainerBasicSx: SxProps = {
   position: "absolute",
   cursor: "pointer",
   borderRadius: "50%",
   ":hover": {
      ".coloredBackground": {
         opacity: 0.2,
      },
   },
};

interface IPlayerAndCommanderComponentProps {
   index: TPlayerIndex;
   selectedPlayerSummaries: Array<UserSummary>;
   playerUserSummary: Maybe<UserSummary>;
   existingPlayer?: Maybe<PlayerSummary>;
   playerCommanderPair: Maybe<CommanderPair>;
   setPlayerSummary: (summary: UserSummary) => void;
   setWinner: Procedure;
   wonGame: boolean;
   setPlayerCommanderPair: (commanderPair: CommanderPair) => void;
   width: number;
   height: number;
   displacementRatio: number;
   mode: ComponentMode;
   onClickCommanderPair?: Consumer<Maybe<CommanderPair>>;
}

const PlayerAndCommanderComponent: React.FC<IPlayerAndCommanderComponentProps> = ({
   index,
   selectedPlayerSummaries,
   existingPlayer,
   playerUserSummary,
   playerCommanderPair,
   setWinner,
   wonGame,
   setPlayerSummary,
   setPlayerCommanderPair,
   width,
   height,
   displacementRatio,
   mode,
   onClickCommanderPair,
}) => {
   const {
      findUserDefaultCommanderById: { call: findDefaultCommanders },
   } = useApplicationApi();

   const setPlayerAndDefaultCommander = useSetPlayerAndDefaultCommander(
      findDefaultCommanders,
      playerCommanderPair,
      setPlayerCommanderPair,
      setPlayerSummary
   );

   const isLeftSide = useMemo(() => index === 1 || index === 4, [index]);
   const isTopSide = useMemo(() => index === 1 || index === 2, [index]);
   const isRightSide = useMemo(() => index === 2 || index === 3, [index]);
   const isBottomSide = useMemo(() => index === 3 || index === 4, [index]);
   const showSecondaryCommanderPicker: boolean = Boolean(
      playerCommanderPair?.commander1.partner ||
         playerCommanderPair?.commander1.requiresBackground ||
         playerCommanderPair?.commander1.friendsForever
   );

   const playerAndCommanderComponentContainerSx: SxProps = useMemo(() => {
      const left = isLeftSide ? `-${width * displacementRatio}vmin` : "auto";
      const top = isTopSide ? `-${height * displacementRatio}vmin` : "auto";
      const right = isRightSide ? `-${width * displacementRatio}vmin` : "auto";
      const bottom = isBottomSide ? `-${height * displacementRatio}vmin` : "auto";
      return {
         ...playerAndCommanderComponentContainerBasicSx,
         width: `${width}vmin`,
         height: `${height}vmin`,
         left: left,
         top: top,
         right: right,
         bottom: bottom,
      };
   }, [displacementRatio, height, isBottomSide, isLeftSide, isRightSide, isTopSide, width]);

   const playerNameContainerSx: SxProps = useMemo(() => {
      return {
         width: "100%",
         position: "absolute",
         display: "flex",
         alignItems: "center",
         left: isLeftSide ? 0 : "auto",
         right: isRightSide ? 0 : "auto",
         top: isTopSide ? 0 : "auto",
         bottom: isBottomSide ? 0 : "auto",
      };
   }, [isBottomSide, isLeftSide, isRightSide, isTopSide]);

   const onClickCard = useCallback(() => {
      onClickCommanderPair && onClickCommanderPair(playerCommanderPair);
   }, [onClickCommanderPair, playerCommanderPair]);

   const playerColor = useMemo(
      () =>
         index === 1
            ? PLAYER1_TEXT_COLOR
            : index === 2
            ? PLAYER2_TEXT_COLOR
            : index === 3
            ? PLAYER3_TEXT_COLOR
            : PLAYER4_TEXT_COLOR,
      [index]
   );

   const coloredBackgroundSx: SxProps = useMemo(() => {
      return {
         ...coloredBackgroundBasicSx,
         width: `${width * 1.8}vmin`,
         height: `${height * 1.8}vmin`,
         left: isLeftSide ? "auto" : "-10%",
         right: isRightSide ? "auto" : "-10%",
         top: isTopSide ? "auto" : "-10%",
         bottom: isBottomSide ? "auto" : "-10%",
         background: `radial-gradient(circle, ${playerColor} 0%, rgba(255,255,255,0) 70%, rgba(255,255,255,0) 100%)`,
         borderTopLeftRadius: index === 3 ? "0%" : "50%",
         borderTopRightRadius: index === 4 ? "0%" : "50%",
         borderBottomRightRadius: index === 1 ? "0%" : "50%",
         borderBottomLeftRadius: index === 2 ? "0%" : "50%",
      };
   }, [height, index, isBottomSide, isLeftSide, isRightSide, isTopSide, playerColor, width]);

   const cardPlaceHolderWidthInVmin = useMemo(() => width * 0.5, [width]);
   const commander1RequiresBackground = Boolean(playerCommanderPair?.commander1.requiresBackground);
   const commander1HasFriendsForever = Boolean(playerCommanderPair?.commander1.friendsForever);

   const cardHolders = useMemo(() => {
      const cards = [
         <CardPlaceHolderComponent
            card={playerCommanderPair?.commander1}
            setCard={(card) => setPlayerCommanderPair({ commander1: card, commander2: null })}
            disallowedCards={[]}
            width={height * 0.5}
            position={"absolute"}
            left={isLeftSide ? undefined : 0}
            right={isRightSide ? undefined : 0}
            top={isTopSide ? undefined : 0}
            bottom={isBottomSide ? undefined : 0}
            hoverText={"1st commander"}
            filterCommanders={true}
            filterPartners={false}
            filterBackgrounds={false}
            filterFriendsForever={false}
            mode={mode}
            key={"commander1"}
            cardWidthUnit={"vmin"}
            onClick={onClickCard}
         />,
         showSecondaryCommanderPicker ? (
            <CardPlaceHolderComponent
               card={playerCommanderPair?.commander2}
               setCard={(card) =>
                  playerCommanderPair &&
                  setPlayerCommanderPair({
                     ...playerCommanderPair,
                     commander2: card,
                  })
               }
               disallowedCards={[]}
               width={cardPlaceHolderWidthInVmin}
               position={"absolute"}
               left={isLeftSide ? undefined : width * 0.15}
               right={isRightSide ? undefined : width * 0.15}
               top={isTopSide ? undefined : height * 0.15}
               bottom={isBottomSide ? undefined : height * 0.15}
               hoverText={"2nd commander"}
               hoverTextPlacement={isBottomSide ? "bottom" : "top"}
               filterCommanders={!commander1RequiresBackground}
               filterPartners={!commander1RequiresBackground}
               filterBackgrounds={commander1RequiresBackground}
               filterFriendsForever={commander1HasFriendsForever}
               mode={mode}
               key={"commander2"}
               cardWidthUnit={"vmin"}
               onClick={onClickCard}
            />
         ) : null,
      ];

      return isTopSide ? cards.reverse() : cards;
   }, [
      cardPlaceHolderWidthInVmin,
      commander1HasFriendsForever,
      commander1RequiresBackground,
      height,
      isBottomSide,
      isLeftSide,
      isRightSide,
      isTopSide,
      mode,
      onClickCard,
      playerCommanderPair,
      setPlayerCommanderPair,
      showSecondaryCommanderPicker,
      width,
   ]);

   return (
      <Box id={`playerAndCommanderComponent${index}`} sx={playerAndCommanderComponentContainerSx}>
         <Box className={`coloredBackground`} sx={coloredBackgroundSx} />
         <PlayerStatusComponent
            show={mode === ComponentMode.VIEW}
            isWinner={wonGame}
            status={existingPlayer?.status}
            left={isLeftSide ? `${-width * 0.66}vmin` : "auto"}
            right={isRightSide ? `${-width * 0.66}vmin` : "auto"}
            top={isTopSide ? 0 : "auto"}
            bottom={isBottomSide ? 0 : "auto"}
         />
         <Box sx={playerNameContainerSx}>
            <UserSelectorAutocomplete
               mode={mode}
               playerColor={playerColor}
               playerIndex={index}
               playerSummary={playerUserSummary}
               selectPlayerHandler={setPlayerAndDefaultCommander}
               otherPlayers={selectedPlayerSummaries}
               setWinner={setWinner}
               wonGame={wonGame}
               showStatsButton={mode === ComponentMode.VIEW}
            />
            {/*{playerUserSummary && <WinnerIcon won={wonGame} onClick={setThisWinner} readonly={mode === "VIEW"} />}*/}
         </Box>
         {cardHolders}
      </Box>
   );
};

export default PlayerAndCommanderComponent;
