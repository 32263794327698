import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
   Box,
   Button,
   Checkbox,
   Fab,
   Fade,
   FormControlLabel,
   FormGroup,
   Grow,
   InputLabel,
   MenuItem,
   Select,
   Slide,
   TextField,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import useCurrentUser from "../../auth/useCurrentUser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SxProps } from "@mui/system";
import { MAIN_PAGE_WELCOME_SCREEN, PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH } from "../../UI_CONST";
import { TournamentStatus, useTournamentApi } from "../../rest.client/useTournamentApi";
import { Maybe } from "../../TYPE";
import FormControl from "@mui/material/FormControl";
import HomeIcon from "@mui/icons-material/Home";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import { HOME_PATH, LOGIN_PATH } from "../../index";
import CircularProgress from "@mui/material/CircularProgress";
import TournamentTile from "./TournamentTile";
import { Procedure } from "../../utils";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";

const searchBarSpacing = 20;

const rootSx: SxProps = {
   position: "relative",
   width: "100%",
   height: "100%",
   background: "linear-gradient(to bottom, black 0%, transparent 50%, black 100%)",
};

const tilesPanelSx: SxProps = {
   position: "absolute",
   overflow: "auto",
   width: { xs: "100%", sm: `calc(100% - ${PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH}px)` },
   height: "100%",
   top: 0,
   right: 0,
   display: "flex",
   flexWrap: "wrap",
   alignItems: "flex-start",
   padding: { xs: `10px 10px 100px 10px`, sm: `20px 20px 120px 30px` },
   gap: { xs: `10px`, sm: `20px` },
};

const filterWrapperSx: SxProps = {
   position: "absolute",
   width: `${PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH}px`,
   top: 0,
   left: 0,
   height: "100%",
   display: "flex",
   flexDirection: "column",
   padding: "10px",
   boxSizing: "border-box",
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
   backdropFilter: "blur(5px)",
   background: "linear-gradient(to bottom, black 0%, rgba(0,0,0,0.5) 50%, black 100%)",
};

const backBtnSx: SxProps = {
   margin: "0 auto 20px auto",
};

const closeBtnSx: SxProps = {
   margin: "auto auto 0 auto",
};

const rectCreateBtnSx: SxProps = {
   fontSize: "1em",
   textWrap: "nowrap",
   textShadow: "0 0 1px black, 0 0 2px black, 0 0 3px black",
};

const fabCreateBtnSx: SxProps = {
   position: "fixed",
   right: "10px",
   bottom: "70px",
};

const searchFieldWrapperSx: SxProps = {
   position: "fixed",
   bottom: 0,
   left: { xs: 0, sm: `${PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH + searchBarSpacing}px` },
   width: { xs: "100%", sm: `calc(100% - ${PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH}px - ${2 * searchBarSpacing}px)` },
   padding: "10px",
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
   backdropFilter: "blur(3px) sepia(1)",
   borderRadius: "5px",
   display: "flex",
   justifyContent: "flex-start",
   alignItems: "center",
   gap: "10px",
   background: "linear-gradient(to bottom, black, rgba(0,0,0,0.5), black)",
};

const noTournamentPanelSx: SxProps = {
   margin: "auto",
   padding: "20px",
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   alignItems: "center",
   gap: "10px",
   backdropFilter: "blur(5px)",
   background: "linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, transparent 50%, rgba(0,0,0,0.5) 100%)",
   borderRadius: "5px",
   boxShadow: `0 0 0.8vmin 0.1vmin black`,
};

const backdropSx: SxProps = {
   position: "absolute",
   left: 0,
   top: 0,
   width: "100%",
   height: "100%",
   background: "rgba(0,0,0, 0.4)",
};

export default function TournamentsContent({ handleCreateButtonClick }: { handleCreateButtonClick: Procedure }) {
   const theme = useTheme();
   const nav = useNavigate();
   const user = useCurrentUser();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [openFilterPanel, setOpenFilterPanel] = useState(!smallScreen);
   const [searchingTournaments, setSearchTournaments] = useState<boolean>(false);
   let [searchParams, setSearchParams] = useSearchParams();
   const {
      getAllTournaments: { call: getAllTournaments, responseData: getAllTournamentsResponseData },
   } = useTournamentApi();
   const [textFilterTemp, setTextFilterTemp] = useState(searchParams.get("searchText"));
   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const filterFutureEvents =
      searchParams.get("filterFutureEvents") == null || searchParams.get("filterFutureEvents") === "true";
   const filterOrganisedByMe = searchParams.get("filterOrganisedByMe") === "true";
   const filterByText = searchParams.get("filterByText");
   const filterByStatus = searchParams.get("filterByStatus");
   const filterJoinedByMe = searchParams.get("filterJoinedByMe") === "true";
   const fullFilterParams = useMemo(
      () => ({
         filterFutureEvents: filterFutureEvents + "",
         filterOrganisedByMe: filterOrganisedByMe + "",
         filterJoinedByMe: filterJoinedByMe + "",
         filterByText: filterByText == null ? "" : filterByText,
         filterByStatus: filterByStatus == null ? "" : filterByStatus,
      }),
      [filterByStatus, filterByText, filterFutureEvents, filterJoinedByMe, filterOrganisedByMe]
   );
   const statusFilterToSearch =
      filterByStatus != null
         ? filterByStatus === "ANY" || filterByStatus === ""
            ? null
            : (filterByStatus as TournamentStatus)
         : null;
   const statusFilterToSelect = statusFilterToSearch == null ? "ANY" : statusFilterToSearch;

   useEffect(() => {
      setSearchTournaments(true);
      const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
      getAllTournaments({
         queryParams: {
            page: 0,
            size: 25,
            projection: "summary",
            sort: "startDate,desc",
            afterDate: filterFutureEvents ? yesterday.toISOString() : null,
            organiserUserId: filterOrganisedByMe ? user.user?.uid : null,
            joinedUserId: filterJoinedByMe ? user.user?.uid : null,
            status: statusFilterToSearch,
            searchText: filterByText,
         },
      }).finally(() => setSearchTournaments(false));
   }, [
      filterByStatus,
      filterByText,
      filterFutureEvents,
      filterJoinedByMe,
      filterOrganisedByMe,
      getAllTournaments,
      statusFilterToSearch,
      user.user?.uid,
   ]);

   const allTournaments = useMemo(() => {
      return getAllTournamentsResponseData ? getAllTournamentsResponseData._embedded.tournaments : [];
   }, [getAllTournamentsResponseData]);

   const userSearchHandler: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
      (ev) => {
         if (searchActiveTimeout != null) {
            clearTimeout(searchActiveTimeout);
            setSearchActiveTimeout(null);
         }
         const timeOutId = window.setTimeout(() => {
            setSearchParams({ ...fullFilterParams, filterByText: ev.target.value });
         }, 500);
         setTextFilterTemp(ev.target.value);
         setSearchActiveTimeout(timeOutId);
      },
      [fullFilterParams, searchActiveTimeout, setSearchParams]
   );

   useEffect(() => {
      if (!smallScreen && !openFilterPanel) {
         setOpenFilterPanel(true);
      }
   }, [openFilterPanel, smallScreen]);

   const tournamentTiles = useMemo(() => {
      if (searchingTournaments) return <CircularProgress sx={{ margin: "auto" }} size={80} />;
      if (allTournaments.length > 0) {
         return allTournaments.map((tournamentSummary) => (
            <TournamentTile key={tournamentSummary.id} tournamentSummary={tournamentSummary} />
         ));
      } else {
         return (
            <Box sx={noTournamentPanelSx}>
               <Typography variant={"h6"}>{"No tournaments match your filters!"}</Typography>
               {user.loggedIn ? (
                  <Button variant={"contained"} onClick={handleCreateButtonClick}>
                     {"Create your tournament!"}
                  </Button>
               ) : (
                  <Button variant={"contained"} onClick={() => nav(LOGIN_PATH)}>
                     {"Sign in/sign up to create your tournament!"}
                  </Button>
               )}
            </Box>
         );
      }
   }, [allTournaments, handleCreateButtonClick, nav, searchingTournaments, user.loggedIn]);

   const filterPanel = useMemo(
      () => (
         <Slide in={openFilterPanel} direction={"right"} timeout={1000}>
            <Box sx={filterWrapperSx}>
               <Button
                  size={"medium"}
                  fullWidth
                  sx={backBtnSx}
                  color={"secondary"}
                  variant={"contained"}
                  startIcon={<HomeIcon />}
                  onClick={() => {
                     localStorage.setItem("mainPageContent", MAIN_PAGE_WELCOME_SCREEN);
                     nav(HOME_PATH);
                  }}
               >
                  Welcome screen
               </Button>
               <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                     size={"small"}
                     label={"Status"}
                     value={statusFilterToSelect}
                     onChange={(event) => {
                        event.target.value != null &&
                           setSearchParams({
                              ...fullFilterParams,
                              filterByStatus: event.target.value,
                           });
                     }}
                  >
                     <MenuItem value={"SCHEDULED"}>Scheduled</MenuItem>
                     <MenuItem value={"STARTED"}>Started</MenuItem>
                     <MenuItem value={"ENDED"}>Ended</MenuItem>
                     <MenuItem value={"ANY"}>Any</MenuItem>
                  </Select>
               </FormControl>
               <FormGroup row>
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={filterFutureEvents}
                           onChange={() => {
                              setSearchParams({
                                 ...fullFilterParams,
                                 filterFutureEvents: String(!filterFutureEvents),
                              });
                           }}
                        />
                     }
                     label="New events only"
                  />
               </FormGroup>
               {user.user && (
                  <FormGroup row>
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={filterOrganisedByMe}
                              onChange={() => {
                                 setSearchParams({
                                    ...fullFilterParams,
                                    filterOrganisedByMe: String(!filterOrganisedByMe),
                                 });
                              }}
                           />
                        }
                        label="Organised by me"
                     />
                  </FormGroup>
               )}
               {user.user && (
                  <FormGroup row>
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={filterJoinedByMe}
                              onChange={() => {
                                 setSearchParams({
                                    ...fullFilterParams,
                                    filterJoinedByMe: String(!filterJoinedByMe),
                                 });
                              }}
                           />
                        }
                        label="Joined/Invited"
                     />
                  </FormGroup>
               )}
               {smallScreen ? (
                  <Button
                     fullWidth
                     size={"medium"}
                     sx={closeBtnSx}
                     variant={"outlined"}
                     startIcon={<CloseIcon />}
                     onClick={() => setOpenFilterPanel(false)}
                  >
                     Close
                  </Button>
               ) : (
                  <></>
               )}
            </Box>
         </Slide>
      ),
      [
         filterFutureEvents,
         filterJoinedByMe,
         filterOrganisedByMe,
         fullFilterParams,
         nav,
         openFilterPanel,
         setSearchParams,
         smallScreen,
         statusFilterToSelect,
         user.user,
      ]
   );

   return (
      <Box sx={rootSx}>
         <Box id={"tournamentTilesPanel"} sx={tilesPanelSx}>
            {tournamentTiles}
            <Grow in={true} timeout={2000}>
               <Box sx={searchFieldWrapperSx}>
                  {smallScreen ? (
                     <Button
                        size={"medium"}
                        variant={"outlined"}
                        startIcon={<TuneIcon />}
                        onClick={() => setOpenFilterPanel(true)}
                     >
                        Filter
                     </Button>
                  ) : (
                     <></>
                  )}
                  <TextField
                     size={smallScreen ? "small" : "medium"}
                     sx={{ flexGrow: 1 }}
                     variant={"outlined"}
                     defaultValue={""}
                     label={"Search"}
                     value={textFilterTemp}
                     onChange={userSearchHandler}
                  />
                  {!smallScreen ? (
                     <Button
                        variant={"contained"}
                        startIcon={<AddCircleIcon />}
                        onClick={handleCreateButtonClick}
                        sx={rectCreateBtnSx}
                     >
                        Create tournament
                     </Button>
                  ) : (
                     <></>
                  )}
               </Box>
            </Grow>
            {smallScreen && user.loggedIn ? (
               <Fade in={!openFilterPanel} timeout={1000}>
                  <Fab color={"primary"} onClick={handleCreateButtonClick} sx={fabCreateBtnSx}>
                     <AddIcon />
                  </Fab>
               </Fade>
            ) : (
               <></>
            )}
         </Box>
         {smallScreen ? (
            <Fade in={openFilterPanel} timeout={1000}>
               <Box sx={backdropSx} onClick={() => setOpenFilterPanel(false)} />
            </Fade>
         ) : (
            <></>
         )}
         {filterPanel}
      </Box>
   );
}
