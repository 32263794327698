import React, { FC, useCallback } from "react";
import {
   Avatar,
   Box,
   Card,
   CardHeader,
   CardMedia,
   IconButton,
   List,
   ListItem,
   ListItemText,
   Paper,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TournamentSummary } from "../../rest.client/useTournamentApi";
import { toPromoter, toTournamentDetails } from "../../navPaths";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import PersonIcon from "@mui/icons-material/Person";
import { SxProps } from "@mui/system";

const cardSx: SxProps = {
   cursor: "pointer",
   width: { xs: "350px", sm: "500px" },
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
};

interface ITournamentsViewPageTournamentTile {
   tournamentSummary: TournamentSummary;
   elevation?: number;
}

const TournamentTile: FC<ITournamentsViewPageTournamentTile> = ({ tournamentSummary, elevation }) => {
   const nav = useNavigate();
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const showLeagueView = useCallback(
      () => nav(toTournamentDetails(tournamentSummary.id)),
      [nav, tournamentSummary.id]
   );

   return (
      <Card onClick={showLeagueView} raised={true} elevation={elevation} sx={cardSx}>
         <Paper elevation={3}>
            <CardMedia
               component="img"
               height={smallScreen ? "200px" : "300px"}
               image={tournamentSummary?.logoUrl != null ? tournamentSummary.logoUrl : "/no_logo.png"}
               alt={tournamentSummary.name + " logo"}
            />
            <Box>
               {tournamentSummary.promoter?.logoUrl && (
                  <Box
                     sx={{
                        position: "relative",
                        top: "-80px",
                        left: "calc(100% - 100px)",
                        height: "0px",
                     }}
                     onClick={(e) => {
                        e.stopPropagation();
                        nav(toPromoter(tournamentSummary.promoter?.id || ""));
                     }}
                  >
                     <Avatar
                        sx={{ width: 96, height: 96 }}
                        alt={tournamentSummary.promoter.name + " logo"}
                        src={tournamentSummary.promoter.logoUrl}
                     />
                  </Box>
               )}
            </Box>
         </Paper>
         <CardHeader
            action={<IconButton aria-label="settings"></IconButton>}
            title={
               <Typography
                  variant={"h6"}
                  width={smallScreen ? "320px" : "440px"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  fontWeight={"bold"}
               >
                  {tournamentSummary.name}
               </Typography>
            }
            subheader={
               tournamentSummary.location !== "" ? (
                  <Box sx={{ display: "flex" }}>
                     <LocationOnIcon />
                     <Typography
                        variant={"caption"}
                        width={smallScreen ? "280px" : "430px"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                     >
                        {tournamentSummary.location}
                     </Typography>
                  </Box>
               ) : null
            }
         />
         <Box>
            <List dense={true} sx={{ display: "flex", justifyContent: "space-between" }}>
               <ListItem>
                  <ListItemText
                     primary={
                        <Box sx={{ display: "flex" }}>
                           {tournamentSummary.inviteOnly && <LockIcon fontSize={"small"} />}
                           {!tournamentSummary.inviteOnly && <LockOpenIcon fontSize={"small"} />}
                           <b>{tournamentSummary.inviteOnly ? "Invites only" : "Open"}</b>
                        </Box>
                     }
                     secondary={"Registration"}
                  />
               </ListItem>
               <ListItem>
                  <ListItemText
                     primary={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                           <PersonIcon />
                           <b>{tournamentSummary.maxPlayerCount}</b>
                        </Box>
                     }
                     secondary={"Max players"}
                  />
               </ListItem>
               <ListItem>
                  <ListItemText
                     primary={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                           <InsertInvitationIcon />
                           <b>{new Date(tournamentSummary.startDate).toLocaleDateString()}</b>
                        </Box>
                     }
                     secondary={"Start date"}
                  />
               </ListItem>
            </List>
         </Box>
      </Card>
   );
};

export default TournamentTile;
