import React from "react";
import { Box, useTheme } from "@mui/material";
import { RemotePromoter } from "../useRemotePromoter";
import CustomIconButton from "../../common/CustomIconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Procedure } from "../../utils";
import { useFilePicker } from "use-file-picker";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export default function PromoterControls({
   remotePromoter,
   onClickEdit,
}: {
   remotePromoter: RemotePromoter;
   onClickEdit: Procedure;
}) {
   const theme = useTheme();
   const messages = useAddMessageItem();

   const { openFilePicker } = useFilePicker({
      readAs: "ArrayBuffer",
      accept: "image/*",
      multiple: false,
      onFilesSuccessfullySelected: async ({ plainFiles }) => {
         const formData = new FormData();
         formData.append("file", plainFiles[0]);
         await remotePromoter.uploadLogo(formData);
         messages("Logo updated!", "success");
      },
   });

   return (
      <Box>
         {remotePromoter.promoterFullResponse?.isAdmin && (
            <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
               <CustomIconButton icon={<EditIcon />} buttonProps={{ variant: "text" }} onClick={onClickEdit}>
                  Edit
               </CustomIconButton>
               <CustomIconButton icon={<FileUploadIcon />} buttonProps={{ variant: "text" }} onClick={openFilePicker}>
                  Upload logo
               </CustomIconButton>
            </Box>
         )}
      </Box>
   );
}
