import React, { useEffect, useState } from "react";
import {
   Button,
   Dialog,
   DialogContent,
   DialogTitle,
   InputLabel,
   MenuItem,
   Select,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { Procedure } from "../../utils";
import RoundTimerCountdown from "./RoundTimerCountdown";
import { RoundFullResponse } from "../../rest.client/useStagesApi";
import { SxProps } from "@mui/system";
import RoundTimerExtraInfoContainer from "./RoundTimerExtraInfoContainer";
import FormControl from "@mui/material/FormControl";

const TIMER_OPTION_1 = "time_only";
const TIMER_OPTION_2 = "time_and_pair_info";

const TIMER_SIZE = 80; // vmin
const TIMER_TEXT_SIZE = TIMER_SIZE * 0.125;

const titleSx: SxProps = {
   display: "flex",
   columnGap: "10px",
   alignItems: "center",
};

const contentSx: SxProps = {
   display: "flex",
   justifyContent: "center",
   alignItems: "flex-start",
   height: "100%",
};

const speedControlSx: SxProps = {
   width: "140px",
};

const closeBtnSx: SxProps = {
   marginRight: "0px",
   marginLeft: "auto",
};

export default function RoundTimerDialog({
   open,
   onClose,
   round,
}: {
   open: boolean;
   onClose: Procedure;
   round: RoundFullResponse;
}) {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [timerOption, setTimerOption] = useState<string>(TIMER_OPTION_1);
   const [timerSeconds, setTimerSeconds] = useState<number | undefined>(undefined);
   const [speed, setSpeed] = useState<number>(10);
   const speedOptions = [];

   for (let i = 10; i < 100; i += 10) {
      speedOptions.push(<MenuItem value={i}>{`${100 - i}`}</MenuItem>);
   }

   useEffect(() => {
      if (smallScreen && timerOption !== TIMER_OPTION_1) {
         setTimerOption(TIMER_OPTION_1);
      }
   }, [smallScreen, timerOption]);

   return (
      <Dialog
         fullScreen={true}
         open={open}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onClose();
            }
         }}
      >
         <DialogTitle sx={titleSx}>
            <Typography>Round timer</Typography>
            {smallScreen ? (
               <></>
            ) : (
               <Select
                  size={"small"}
                  value={timerOption}
                  onChange={(event) => setTimerOption(event.target.value as string)}
               >
                  <MenuItem value={TIMER_OPTION_1}>Time only</MenuItem>
                  <MenuItem value={TIMER_OPTION_2}>Time and pairing info</MenuItem>
               </Select>
            )}

            {timerOption === TIMER_OPTION_2 ? (
               <FormControl sx={speedControlSx}>
                  <InputLabel>Scrolling speed</InputLabel>
                  <Select
                     label={"Scrolling speed"}
                     size={"small"}
                     value={speed}
                     onChange={(event) => setSpeed(event.target.value as number)}
                  >
                     {speedOptions}
                  </Select>
               </FormControl>
            ) : (
               <></>
            )}
            <Button color={"primary"} sx={closeBtnSx} variant={"contained"} onClick={onClose}>
               Close
            </Button>
         </DialogTitle>
         <DialogContent sx={contentSx}>
            <RoundTimerExtraInfoContainer
               timerSeconds={timerSeconds}
               show={timerOption === TIMER_OPTION_2}
               speed={speed}
            />
            <RoundTimerCountdown
               setTimerSeconds={setTimerSeconds}
               round={round}
               size={`${TIMER_SIZE}vmin`}
               fontSize={`${TIMER_TEXT_SIZE}vmin`}
            />
         </DialogContent>
      </Dialog>
   );
}
