import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, keyframes } from "@mui/material";
import { SxProps } from "@mui/system";
import { RoundFullResponse, useStageApi } from "../../rest.client/useStagesApi";
import { useParams } from "react-router-dom";
import RoundTimerExtraInfo from "./RoundTimerExtraInfo";

const REFRESH_INFO_TIME = 60 * 2; // Fetch data every 2 minutes

const translateYPrimary = keyframes`
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(-100%);
    }
`;

const rootSx: SxProps = {
   position: "relative",
   overflow: "hidden",
   flexGrow: 1,
   height: "100%",
   marginRight: "10px",
};

const loadingWrapperSx: SxProps = {
   position: "relative",
   width: "100%",
   height: "100%",
   display: "flex",
};

const circularProgressSx: SxProps = {
   margin: "auto",
};

const infoWrapperDouble: SxProps = {
   top: "100%",
   position: "absolute",
   width: "100%",
   height: "100%",
};

interface IRoundTimerExtraInfo {
   show: boolean;
   timerSeconds: number | undefined;
   speed: number;
}

export default function RoundTimerExtraInfoContainer({ show, timerSeconds, speed }: IRoundTimerExtraInfo) {
   const {
      getStageFull: { call: getFullStage },
   } = useStageApi();
   const { stageId } = useParams();
   const [roundsData, setRoundsData] = useState<Array<RoundFullResponse>>([]);

   const infoWrapperSx: SxProps = useMemo(
      () => ({
         position: "relative",
         width: "100%",
         minHeight: "100%",
         animation: `${translateYPrimary} ${speed}s infinite linear alternate`,
         boxSizing: "border-box",
         paddingBottom: "10px",
      }),
      [speed]
   );

   useEffect(() => {
      if (
         (roundsData.length === 0 && stageId) ||
         (timerSeconds && timerSeconds % REFRESH_INFO_TIME === 0 && stageId && show)
      ) {
         const fetchData = async () => {
            const response = await getFullStage({ pathParams: { id: stageId } });
            setRoundsData(response.rounds);
         };
         fetchData();
      }
   }, [getFullStage, roundsData.length, show, stageId, timerSeconds]);

   return show ? (
      <Box sx={rootSx}>
         {roundsData.length === 0 ? (
            <Box sx={loadingWrapperSx}>
               <CircularProgress sx={circularProgressSx} />
            </Box>
         ) : (
            <Box sx={infoWrapperSx}>
               {roundsData.map((round) => (
                  <RoundTimerExtraInfo roundData={round} />
               ))}
               <Box sx={infoWrapperDouble}>
                  {roundsData.map((round) => (
                     <RoundTimerExtraInfo roundData={round} />
                  ))}
               </Box>
            </Box>
         )}
      </Box>
   ) : (
      <></>
   );
}
