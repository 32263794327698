import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { SxProps } from "@mui/system";
import { Maybe } from "../TYPE";
import { PromoterSummaryResponse, usePromotersApi } from "../rest.client/usePromotersApi";
import useCurrentUser from "../auth/useCurrentUser";

const PromoterSelectorAutocomplete: React.FC<{
   promoterSummary: Maybe<PromoterSummaryResponse>;
   selectionHandler: (summary: PromoterSummaryResponse) => void;
   readOnly: boolean;
   sx?: SxProps;
}> = ({ selectionHandler, promoterSummary, readOnly, sx }) => {
   const {
      findPromotersByAdminIdAndText: { call: findPromoters, responseData },
   } = usePromotersApi();
   const currentUser = useCurrentUser();

   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const [processingInput, setProcessingInput] = useState(false);

   const searchHandler = useCallback(
      (value: string) => {
         if (searchActiveTimeout != null) {
            clearTimeout(searchActiveTimeout);
            setSearchActiveTimeout(null);
         }
         if (value.length >= 2) {
            setProcessingInput(true);
            const timeOutId = window.setTimeout(() => {
               findPromoters({
                  queryParams: {
                     searchText: value,
                     adminId: currentUser.user?.uid,
                     size: 25,
                     page: 0,
                     sort: "",
                  },
               }).finally(() => {
                  setProcessingInput(false);
               });
            }, 500);
            setSearchActiveTimeout(timeOutId);
         }
      },
      [searchActiveTimeout, findPromoters, currentUser.user?.uid]
   );

   useEffect(() => {
      if (responseData == null) {
         findPromoters({
            queryParams: {
               searchText: "",
               adminId: currentUser.user?.uid,
               size: 25,
               page: 0,
               sort: "",
            },
         });
      }
   }, [currentUser.user?.uid, findPromoters, responseData, searchHandler]);

   const onChange = useCallback(
      (event: SyntheticEvent, val: Maybe<PromoterSummaryResponse>) => {
         val && selectionHandler(val);
      },
      [selectionHandler]
   );

   return (
      <Autocomplete
         forcePopupIcon={false}
         size={"small"}
         onInputChange={(ev, value) => searchHandler(value)}
         onChange={onChange}
         readOnly={readOnly}
         fullWidth={true}
         value={promoterSummary}
         getOptionLabel={(option) => option.name}
         isOptionEqualToValue={(option, value) => option.id === value.id}
         loading={processingInput}
         noOptionsText={"No promoter accounts found."}
         clearOnBlur={true}
         renderInput={(params) => (
            <TextField
               sx={{}}
               label={"Link promoter account"}
               color={"primary"}
               {...params}
               InputProps={{
                  ...params.InputProps,
               }}
            />
         )}
         options={responseData?._embedded.promoters || []}
      />
   );
};

export default PromoterSelectorAutocomplete;
