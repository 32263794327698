import React, { useCallback, useEffect, useMemo, useState } from "react";
import Panel from "./Panel";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Link,
   TextField,
   Typography,
   useTheme,
} from "@mui/material";
import { EMPTY_STRING, GLOBAL_VAR_NAME, TournamentCreationPagePanelIndex } from "../../UI_CONST";
import useGlobal from "../../global/useGlobal";
import { Maybe, TTournamentsPageState } from "../../TYPE";
import { SaveTournamentRequest } from "../../rest.client/useTournamentApi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { dateValidationErrorToErrorMsg } from "../tournamentsPageUtils";
import { useParams } from "react-router-dom";
import { tournamentsPageDefaultState } from "./TournamentCustomFlowDialog";
import { RemoteTournament } from "../useRemoteTournament";
import MarkdownDescriptionDisplay from "../MarkdownDescriptionDisplay";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PromoterSelectorAutocomplete from "../../promoters/PromoterSelectorAutocomplete";
import { PromoterSummaryResponse } from "../../rest.client/usePromotersApi";

const InfoPanel: React.FC<{
   remoteTournament: RemoteTournament;
}> = ({ remoteTournament }) => {
   const theme = useTheme();
   const { tournamentId } = useParams();
   const currentPromoter = remoteTournament.tournament?.promoter;

   const isCreatingNewTournament = remoteTournament.id == null;
   const isEditingExistingTournament = useMemo(
      () => Boolean(!isCreatingNewTournament && tournamentId),
      [isCreatingNewTournament, tournamentId]
   );
   const [selectedPromoter, setSelectedPromoter] = useState<Maybe<PromoterSummaryResponse>>();

   const [tournamentsPageState, setTournamentsPageState] = useGlobal<TTournamentsPageState>(
      GLOBAL_VAR_NAME.TOURNAMENTS_DIALOG_STATE,
      tournamentsPageDefaultState
   );

   const [showTournamentNameFieldError, setShowTournamentNameFieldError] = useState(false);

   const [startDate, setStartDate] = useState<Dayjs>(
      dayjs(
         isEditingExistingTournament
            ? tournamentsPageState.editTournamentRequestData.startDate
            : tournamentsPageState.createTournamentRequestData.startDate
      )
   );
   const [endDate, setEndDate] = useState<Dayjs>(
      dayjs(
         isEditingExistingTournament
            ? tournamentsPageState.editTournamentRequestData.endDate
            : tournamentsPageState.createTournamentRequestData.endDate
      )
   );
   const [startDateError, setStartDateError] = useState<string | null>(null);
   const [endDateError, setEndDateError] = useState<string | null>(null);

   useEffect(() => {
      if (isEditingExistingTournament) {
         setStartDate(dayjs(tournamentsPageState.editTournamentRequestData.startDate));
         setEndDate(dayjs(tournamentsPageState.editTournamentRequestData.endDate));
      }
   }, [
      isEditingExistingTournament,
      tournamentsPageState.editTournamentRequestData.endDate,
      tournamentsPageState.editTournamentRequestData.startDate,
   ]);

   const setPromoter = useCallback(
      (promoter: PromoterSummaryResponse) => {
         setSelectedPromoter(promoter);
         isEditingExistingTournament
            ? setTournamentsPageState({
                 ...tournamentsPageState,
                 editTournamentRequestData: {
                    ...tournamentsPageState.editTournamentRequestData,
                    promoterId: promoter.id,
                 },
              })
            : setTournamentsPageState({
                 ...tournamentsPageState,
                 createTournamentRequestData: {
                    ...tournamentsPageState.createTournamentRequestData,
                    promoterId: promoter.id,
                 },
              });
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   const setTournamentName = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
         setShowTournamentNameFieldError(event.target.value === EMPTY_STRING);
         const newData: SaveTournamentRequest = isEditingExistingTournament
            ? {
                 ...tournamentsPageState.editTournamentRequestData,
                 name: event.target.value,
              }
            : {
                 ...tournamentsPageState.createTournamentRequestData,
                 name: event.target.value,
              };
         isEditingExistingTournament
            ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
            : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   const setTournamentLocation = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
         const newData: SaveTournamentRequest = isEditingExistingTournament
            ? {
                 ...tournamentsPageState.editTournamentRequestData,
                 location: event.target.value,
              }
            : {
                 ...tournamentsPageState.createTournamentRequestData,
                 location: event.target.value,
              };
         isEditingExistingTournament
            ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
            : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   const setTournamentDescription = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
         const newData: SaveTournamentRequest = isEditingExistingTournament
            ? {
                 ...tournamentsPageState.editTournamentRequestData,
                 description: event.target.value,
              }
            : {
                 ...tournamentsPageState.createTournamentRequestData,
                 description: event.target.value,
              };
         isEditingExistingTournament
            ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
            : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   const onTournamentNameFieldBlur = useCallback(() => {
      const name = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.name
         : tournamentsPageState.createTournamentRequestData.name;
      setShowTournamentNameFieldError(name === EMPTY_STRING);
   }, [
      isEditingExistingTournament,
      tournamentsPageState.createTournamentRequestData.name,
      tournamentsPageState.editTournamentRequestData.name,
   ]);

   const onLocationFieldBlur = useCallback(() => {
      const location = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.location
         : tournamentsPageState.createTournamentRequestData.location;
   }, [
      isEditingExistingTournament,
      tournamentsPageState.createTournamentRequestData.location,
      tournamentsPageState.editTournamentRequestData.location,
   ]);

   const onDescriptionFieldBlur = useCallback(() => {
      const description = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.description
         : tournamentsPageState.createTournamentRequestData.description;
   }, [
      isEditingExistingTournament,
      tournamentsPageState.createTournamentRequestData.description,
      tournamentsPageState.editTournamentRequestData.description,
   ]);

   const startDateOnChangeHandler = useCallback(
      (newDate: Dayjs | null) => {
         if (newDate) {
            setStartDate(newDate);
            const newData: SaveTournamentRequest = isEditingExistingTournament
               ? {
                    ...tournamentsPageState.editTournamentRequestData,
                    startDate: newDate.toDate(),
                 }
               : {
                    ...tournamentsPageState.createTournamentRequestData,
                    startDate: newDate.toDate(),
                 };
            isEditingExistingTournament
               ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
               : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
         }
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   const endDateOnChangeHandler = useCallback(
      (newDate: Dayjs | null) => {
         if (newDate) {
            setEndDate(newDate);
            const newData: SaveTournamentRequest = isEditingExistingTournament
               ? {
                    ...tournamentsPageState.editTournamentRequestData,
                    endDate: newDate.toDate(),
                 }
               : {
                    ...tournamentsPageState.createTournamentRequestData,
                    endDate: newDate.toDate(),
                 };
            isEditingExistingTournament
               ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
               : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
         }
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   return (
      <Panel panelIndex={TournamentCreationPagePanelIndex.TOURNAMENT_INFO} panelTitle={"Tournament Info"}>
         <TextField
            fullWidth
            required
            size={"small"}
            variant={"outlined"}
            label={showTournamentNameFieldError ? "Tournament name is required" : "Tournament name"}
            error={showTournamentNameFieldError}
            onChange={setTournamentName}
            onBlur={onTournamentNameFieldBlur}
            value={
               isEditingExistingTournament
                  ? tournamentsPageState.editTournamentRequestData.name
                  : tournamentsPageState.createTournamentRequestData.name
            }
            inputProps={{ maxLength: 100 }}
         />
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
               sx={{ width: "100%" }}
               format={"DD/MM/YYYY"}
               onError={(e) => setStartDateError(dateValidationErrorToErrorMsg(e, true))}
               label={startDateError ? startDateError : "Start date"}
               value={startDate}
               onChange={startDateOnChangeHandler}
               views={undefined}
            />
            <DatePicker
               sx={{ width: "100%" }}
               format={"DD/MM/YYYY"}
               onError={(e) => setEndDateError(dateValidationErrorToErrorMsg(e, false))}
               minDate={startDate}
               label={endDateError ? endDateError : "End date"}
               value={endDate}
               onChange={endDateOnChangeHandler}
               views={undefined}
            />
         </LocalizationProvider>
         <TextField
            fullWidth
            size={"small"}
            variant={"outlined"}
            label={"Location"}
            onChange={setTournamentLocation}
            onBlur={onLocationFieldBlur}
            value={
               isEditingExistingTournament
                  ? tournamentsPageState.editTournamentRequestData.location
                  : tournamentsPageState.createTournamentRequestData.location
            }
            inputProps={{ maxLength: 2000 }}
         />
         {remoteTournament.isCreator && (
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
               <Alert severity={"info"}>Promoter account already linked it cannot be changed.</Alert>
               <PromoterSelectorAutocomplete
                  readOnly={currentPromoter != null}
                  promoterSummary={selectedPromoter || currentPromoter}
                  selectionHandler={setPromoter}
               />
            </Box>
         )}

         <Box sx={{ width: "100%" }}>
            <Alert sx={{ width: "100%" }} severity={"info"}>
               Description supports Markdown learn more
               <Link target={"_blank"} rel="noopener" href={"https://commonmark.org/help/"}>
                  {" "}
                  here
               </Link>
            </Alert>
            <TextField
               fullWidth
               multiline
               rows={15}
               size={"small"}
               variant={"outlined"}
               label={"Description"}
               onChange={setTournamentDescription}
               onBlur={onDescriptionFieldBlur}
               value={
                  isEditingExistingTournament
                     ? tournamentsPageState.editTournamentRequestData.description
                     : tournamentsPageState.createTournamentRequestData.description
               }
               inputProps={{ maxLength: 2000 }}
            />
         </Box>
         <Accordion defaultExpanded={true} sx={{ width: "100%" }}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
               <Typography>Description preview</Typography>
            </AccordionSummary>
            <AccordionDetails>
               <MarkdownDescriptionDisplay
                  description={
                     isEditingExistingTournament
                        ? tournamentsPageState.editTournamentRequestData.description
                        : tournamentsPageState.createTournamentRequestData.description
                  }
               />
            </AccordionDetails>
         </Accordion>
      </Panel>
   );
};

export default InfoPanel;
