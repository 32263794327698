import React, { SyntheticEvent, useCallback, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { SxProps } from "@mui/system";
import { Maybe } from "../TYPE";
import useCurrentUser from "../auth/useCurrentUser";
import { TournamentSummary, useTournamentApi } from "../rest.client/useTournamentApi";

const TournamentSelectorAutocomplete: React.FC<{
   tournamentSummary: Maybe<TournamentSummary>;
   selectionHandler: (summary: TournamentSummary) => void;
   sx?: SxProps;
}> = ({ selectionHandler, tournamentSummary, sx }) => {
   const {
      findTournamentsEligibleForPromoterLink: { call: findTournaments, responseData },
   } = useTournamentApi();
   const currentUser = useCurrentUser();

   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const [processingInput, setProcessingInput] = useState(false);

   const searchHandler = useCallback(
      (value: string) => {
         if (searchActiveTimeout != null) {
            clearTimeout(searchActiveTimeout);
            setSearchActiveTimeout(null);
         }
         if (value.length >= 2) {
            setProcessingInput(true);
            const timeOutId = window.setTimeout(() => {
               findTournaments({
                  queryParams: {
                     searchText: value,
                     userId: currentUser.user?.uid || "",
                     size: 25,
                     page: 0,
                     sort: "",
                     projection: "summary",
                  },
               }).finally(() => {
                  setProcessingInput(false);
               });
            }, 500);
            setSearchActiveTimeout(timeOutId);
         }
      },
      [searchActiveTimeout, findTournaments, currentUser.user?.uid]
   );

   const findAllLinkable = useCallback(() => {
      findTournaments({
         queryParams: {
            searchText: "",
            userId: currentUser.user?.uid || "",
            size: 25,
            page: 0,
            sort: "",
            projection: "summary",
         },
      });
   }, [currentUser.user?.uid, findTournaments]);

   const onChange = useCallback(
      (event: SyntheticEvent, val: Maybe<TournamentSummary>) => {
         val && selectionHandler(val);
      },
      [selectionHandler]
   );

   return (
      <Autocomplete
         forcePopupIcon={false}
         size={"small"}
         onInputChange={(ev, value) => searchHandler(value)}
         onChange={onChange}
         fullWidth={true}
         onFocus={findAllLinkable}
         value={tournamentSummary || null}
         getOptionLabel={(option) => option.name}
         isOptionEqualToValue={(option, value) => option.id === value.id}
         loading={processingInput}
         noOptionsText={"No tournaments found."}
         clearOnBlur={true}
         renderInput={(params) => (
            <TextField
               sx={{}}
               label={"Search to link tournaments"}
               color={"primary"}
               {...params}
               InputProps={{
                  ...params.InputProps,
               }}
            />
         )}
         options={responseData?._embedded.tournaments || []}
      />
   );
};

export default TournamentSelectorAutocomplete;
