import { useMemo } from "react";
import { useAxiosInstance } from "./useAxiosInstance";
import useRequest from "./useRequest";
import { PageableQueryParams, PageableResponse, UserSummary } from "./useApplicationApi";
import { Maybe } from "../TYPE";

export function usePromotersApi() {
   const instance = useAxiosInstance();

   const api = {
      createPromoter: useRequest<PromoterSummaryResponse, never, SavePromoterRequest, never>(
         useMemo(
            () => ({
               url: "/promoters",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updatePromoter: useRequest<PromoterSummaryResponse, { id: string }, SavePromoterRequest, never>(
         useMemo(
            () => ({
               url: "/promoters/{id}/update",
               method: "post",
            }),
            []
         ),
         instance
      ),
      addPromoterAdmin: useRequest<
         PromoterSummaryResponse,
         {
            id: string;
         },
         AddAdminRequest,
         never
      >(
         useMemo(
            () => ({
               url: "/promoters/{id}/admins",
               method: "post",
            }),
            []
         ),
         instance
      ),
      addPromoterOrganiser: useRequest<
         PromoterSummaryResponse,
         {
            id: string;
         },
         AddOrganiserRequest,
         never
      >(
         useMemo(
            () => ({
               url: "/promoters/{id}/organisers",
               method: "post",
            }),
            []
         ),
         instance
      ),
      removePromoterOrganiser: useRequest<
         PromoterSummaryResponse,
         {
            id: string;
            organiserId: string;
         },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/promoters/{id}/organisers/{organiserId}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      removePromoterAdmin: useRequest<
         PromoterSummaryResponse,
         {
            id: string;
            adminId: string;
         },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/promoters/{id}/admins/{adminId}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      getFullPromoter: useRequest<PromoterFullResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/promoters/{id}?projection=full",
               method: "get",
            }),
            []
         ),
         instance
      ),
      findPromotersByUserIdAndText: useRequest<
         PromoterSummariesResponse,
         never,
         never,
         PromoterFindByIdAndTextQueryParams
      >(
         useMemo(
            () => ({
               url: "/promoters/search/byFilter/",
               method: "get",
               queryParamsDefault: {
                  projection: "summary",
                  userId: "",
                  size: 25,
                  page: 0,
                  sort: "",
               },
            }),
            []
         ),
         instance
      ),
      linkTournamentToPromoter: useRequest<
         PromoterSummaryResponse,
         {
            id: string;
         },
         { tournamentId: string },
         never
      >(
         useMemo(
            () => ({
               url: "/promoters/{id}/tournaments",
               method: "post",
            }),
            []
         ),
         instance
      ),
      findPromotersByAdminIdAndText: useRequest<
         PromoterSummariesResponse,
         never,
         never,
         PromoterFindByAdminIdAndTextQueryParams
      >(
         useMemo(
            () => ({
               url: "/promoters/search/byAdminUserIdAndText/",
               method: "get",
               queryParamsDefault: {
                  projection: "summary",
                  userId: "",
                  size: 25,
                  page: 0,
                  sort: "",
               },
            }),
            []
         ),
         instance
      ),
      uploadLogo: useRequest<never, { id: string }, FormData, never>(
         useMemo(
            () => ({
               url: "/promoters/{id}/logo",
               method: "post",
               configMutator: (baseConfig) => {
                  if (!baseConfig.headers) {
                     baseConfig.headers = {};
                  }
                  baseConfig.headers["Content-Type"] = "multipart/form-data";
                  return baseConfig;
               },
            }),
            []
         ),
         instance
      ),
   };
   const loading = Object.values(api).some((r) => Boolean(r.loading));
   return { ...api, loading };
}

export interface PromoterSummariesResponse extends PageableResponse<{ promoters: PromoterSummaryResponse[] }> {}

export interface PromoterFindByIdAndTextQueryParams extends PageableQueryParams {
   userId?: Maybe<string>;
   searchText?: Maybe<string>;
}

export interface PromoterFindByAdminIdAndTextQueryParams extends PageableQueryParams {
   adminId?: Maybe<string>;
   searchText?: Maybe<string>;
}

export interface PromoterSummaryResponse {
   id: string;
   name: string;
   location?: string;
   logoUrl?: string;
}

export interface PromoterFullResponse extends PromoterSummaryResponse {
   description?: string;
   owner: UserSummary;
   organisers: UserSummary[];
   admins: UserSummary[];
   isAdmin: boolean;
   isOrganiser: boolean;
}

export interface SavePromoterRequest {
   name: string;
   location?: Maybe<string>;
   description?: Maybe<string>;
}

export interface AddAdminRequest {
   userId: string;
}

export interface AddOrganiserRequest {
   userId: string;
}
