import { useCallback, useMemo, useState } from "react";
import { PromoterFullResponse, PromoterSummaryResponse, usePromotersApi } from "../rest.client/usePromotersApi";
import { Maybe } from "../TYPE";

export interface RemotePromoter {
   promoterData: {
      id: Maybe<string>;
      description: Maybe<string>;
      location: Maybe<string>;
      name: string;
   };
   promoterFullResponse: Maybe<PromoterFullResponse>;
   uploadLogo: (image: FormData) => Promise<unknown>;
   dataInError: boolean;
   loadData: () => Promise<void>;
   setDescription: (description: Maybe<string>) => void;
   setName: (name: string) => void;
   setLocation: (location: Maybe<string>) => void;
   addAdmin: (uid: string) => Promise<void>;
   linkTournament: (uid: string) => Promise<void>;
   addOrganiser: (uid: string) => Promise<void>;
   removeAdmin: (uid: string) => Promise<void>;
   removeOrganiser: (uid: string) => Promise<void>;
   savePromoter: () => Promise<PromoterSummaryResponse>;
   loading: boolean;
}

export default function useRemotePromoter(promoterId?: string): RemotePromoter {
   const {
      createPromoter: { call: createPromoterCall },
      updatePromoter: { call: updatePromoterCall },
      getFullPromoter: { call: getFullPromoterCall, responseData: fullPromoterData },
      addPromoterAdmin: { call: addAdminCall },
      addPromoterOrganiser: { call: addOrganiserCall },
      removePromoterAdmin: { call: removeAdminCall },
      removePromoterOrganiser: { call: removeOrganiserCall },
      linkTournamentToPromoter: { call: linkTournamentCall },
      uploadLogo: { call: uploadLogoCall },
      loading,
   } = usePromotersApi();
   const [name, setName] = useState<string>("");
   const [description, setDescription] = useState<Maybe<string>>("");
   const [location, setLocation] = useState<Maybe<string>>("");
   const dataInError = name === "" || name == null;

   const loadData = useCallback(async () => {
      if (promoterId) {
         const fullResponse = await getFullPromoterCall({ pathParams: { id: promoterId } });
         setName(fullResponse.name);
         setDescription(fullResponse.description);
         setLocation(fullResponse.location);
      }
   }, [getFullPromoterCall, promoterId]);

   const savePromoter = useCallback(async () => {
      if (promoterId) {
         return await updatePromoterCall({ pathParams: { id: promoterId }, body: { name, location, description } });
      } else {
         return await createPromoterCall({ body: { name, location, description } });
      }
   }, [createPromoterCall, description, location, name, promoterId, updatePromoterCall]);

   const uploadLogo = useCallback(
      async (image: FormData) => {
         if (promoterId) {
            await uploadLogoCall({ pathParams: { id: promoterId }, body: image });
            await loadData();
         }
      },
      [loadData, promoterId, uploadLogoCall]
   );

   const addAdmin = useCallback(
      async (uid: string) => {
         if (promoterId) {
            await addAdminCall({ pathParams: { id: promoterId }, body: { userId: uid } });
            await loadData();
         }
      },
      [addAdminCall, loadData, promoterId]
   );

   const linkTournament = useCallback(
      async (id: string) => {
         if (promoterId) {
            await linkTournamentCall({ pathParams: { id: promoterId }, body: { tournamentId: id } });
            await loadData();
         }
      },
      [linkTournamentCall, loadData, promoterId]
   );

   const addOrganiser = useCallback(
      async (uid: string) => {
         if (promoterId) {
            await addOrganiserCall({ pathParams: { id: promoterId }, body: { userId: uid } });
            await loadData();
         }
      },
      [addOrganiserCall, loadData, promoterId]
   );

   const removeAdmin = useCallback(
      async (uid: string) => {
         if (promoterId) {
            await removeAdminCall({ pathParams: { id: promoterId, adminId: uid } });
            await loadData();
         }
      },
      [loadData, promoterId, removeAdminCall]
   );

   const removeOrganiser = useCallback(
      async (uid: string) => {
         if (promoterId) {
            await removeOrganiserCall({ pathParams: { id: promoterId, organiserId: uid } });
            await loadData();
         }
      },
      [loadData, promoterId, removeOrganiserCall]
   );

   return useMemo(
      () => ({
         promoterData: {
            id: fullPromoterData?.id,
            description: description,
            location,
            name,
         },
         promoterFullResponse: fullPromoterData,
         addAdmin,
         uploadLogo,
         dataInError,
         loadData,
         removeAdmin,
         linkTournament,
         removeOrganiser,
         addOrganiser,
         savePromoter,
         setDescription,
         setLocation,
         setName,
         loading,
      }),

      [
         addAdmin,
         addOrganiser,
         dataInError,
         description,
         fullPromoterData,
         linkTournament,
         loadData,
         loading,
         location,
         name,
         removeAdmin,
         removeOrganiser,
         savePromoter,
         uploadLogo,
      ]
   );
}
