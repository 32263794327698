import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { Box, Button, ButtonGroup, InputLabel, Select, Theme, Typography, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import { CARD_HEIGHT_WIDTH_RATIO } from "../../UI_CONST";
import { Maybe } from "../../TYPE";
import { GameFull, useApplicationApi } from "../../rest.client/useApplicationApi";
import { empty } from "../../utils";
import { endingTurnOptions, PlayerPosition, winConditions } from "../viewGamePage/GameViewer";
import { CardNameAndImage } from "../../cardFeatures/CardTypes";
import CardWithButtonsComponent from "../../cardFeatures/cardsCircleDisplay/CardWithButtonsComponent";
import CardPickerComponent from "../../cardFeatures/CardPickerComponent";
import WinnerDropDown from "../WinnerDropDown";
import { ANONYMOUS_USER_ID } from "../UserSelectorAutocomplete";
import useCurrentUser from "../../auth/useCurrentUser";
import { useNavigate } from "react-router-dom";
import useReload from "../../header/useReload";
import DeleteGameConfirmDialog from "../DeleteGameConfirmDialog";
import { MY_GAMES_PATH } from "../../index";

const cardWidth = 30;
const cardTop = 6;

const rootContainerSx: SxProps = {
   display: "flex",
   flexDirection: "column",
   marginLeft: "5px",
   marginRight: "5px",
};

export const createGameButtonWrapperSx: SxProps = {
   margin: "3vh auto",
};

export const optionalDataContainerSx: SxProps<Theme> = {
   borderTop: (theme) => `1px ${theme.palette.secondary.dark} solid`,
   boxSizing: "border-box",
   gap: "6vh 2vw",
   display: "flex",
   flexWrap: "wrap",
   overflow: "visible",
   justifyContent: "center",
   flexGrow: "1",
   alignContent: "flex-start",
   position: "relative",
   paddingTop: "4vh",
};

export const optionalDataContainerTitleSx: SxProps<Theme> = {
   padding: "0 4vw",
   left: "50%",
   top: 0,
   transform: "translateX(-50%) translateY(-50%)",
   position: "absolute",
   color: (theme) => theme.palette.secondary.dark,
   backdropFilter: "blur(10px)",
};

const endingTurnFieldBasicSx: SxProps = {
   width: "30vw",
};

const winConditionFieldBasicSx: SxProps = {
   width: "60vw",
};

const cardsContainerSx: SxProps<Theme> = {
   width: "100%",
   height: `calc(${cardWidth * CARD_HEIGHT_WIDTH_RATIO}vw + ${cardTop + 2}vmin)`,
   border: (theme) => `1px ${theme.palette.secondary.dark} solid`,
   display: "flex",
   position: "relative",
   overflow: "visible",
   borderRadius: "4px",
};

const cardsContainerTitleSx: SxProps<Theme> = {
   padding: "0 2vw",
   left: "30%",
   top: "0",
   transform: "translateX(-50%) translateY(-50%)",
   position: "absolute",
   color: (theme) => theme.palette.text.secondary,
   backdropFilter: "blur(10px)",
};

const cardWrapperBasicSx: SxProps = {
   position: "absolute",
   width: `${cardWidth}vw`,
   height: `${cardWidth * CARD_HEIGHT_WIDTH_RATIO}vw`,
   top: `${cardTop}vmin`,
};

interface IMobileOptionalGameDataStepComponentProps {
   createOrUpdateGameButtonElement?: ReactNode;
   existingGame: GameFull;
   winningPlayer: Maybe<PlayerPosition>;
}

const MobileOptionalGameDataStepComponent: React.FC<IMobileOptionalGameDataStepComponentProps> = ({
   createOrUpdateGameButtonElement,
   existingGame,
   winningPlayer,
}) => {
   const theme = useTheme();
   const userData = useCurrentUser();
   const nav = useNavigate();
   const { requestReload } = useReload();

   const [showHighImpactCardPicker, setShowHighImpactCardPicker] = useState(false);
   const [showWinConditionCardPicker, setShowWinConditionCardPicker] = useState(false);
   const [selectedGameWonByCardIndex, setSelectedGameWonByCardIndex] = useState<Maybe<number>>(null);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const ownsGame = existingGame?.creator.id === userData?.user?.uid;

   const {
      deleteGame: { call: deleteGameRequest },
      approveGame: { call: approveGame },
      disputeGame: { call: disputeGame },
   } = useApplicationApi();

   const endingTurnFieldSx: SxProps = useMemo(() => ({ ...endingTurnFieldBasicSx, pointerEvents: "none" }), []);
   const winConditionFieldSx: SxProps = useMemo(() => ({ ...winConditionFieldBasicSx, pointerEvents: "none" }), []);

   const approveGameCb = useCallback(() => {
      if (existingGame) {
         approveGame({ pathParams: { id: existingGame.id } }).finally(() => {
            requestReload();
         });
      } else {
         throw new Error("No game to approve");
      }
   }, [approveGame, existingGame, requestReload]);

   const deleteGame = useCallback(() => {
      existingGame != null && deleteGameRequest({ pathParams: { id: existingGame.id } }).then(() => nav(MY_GAMES_PATH));
   }, [deleteGameRequest, existingGame, nav]);

   const disputeGameCb = useCallback(() => {
      if (existingGame) {
         disputeGame({ pathParams: { id: existingGame.id } }).finally(() => {
            requestReload();
         });
      } else {
         throw new Error("No game to approve");
      }
   }, [disputeGame, existingGame, requestReload]);

   const endingTurns = existingGame.turns;
   const winCondition = existingGame.winType;

   const gameWonByCards: Array<CardNameAndImage> = existingGame.gameWonBy;
   const highImpactCards: Array<CardNameAndImage> = existingGame.highImpactCards;

   const highImpactCardsToShow = useMemo(() => {
      const numOfCards = highImpactCards.length;
      if (numOfCards > 0) {
         const gap = 100 / (numOfCards + 1);
         return highImpactCards.map((cardInfo, i) => {
            const cardLeft = `calc(${(i + 1) * gap}% - ${cardWidth * 0.5}vw)`;
            const cardWrapperSx = { ...cardWrapperBasicSx, left: cardLeft };
            return (
               <Box sx={cardWrapperSx}>
                  <CardWithButtonsComponent
                     showButtons={false}
                     cardWidth={cardWidth}
                     cardWidthUnit={"vw"}
                     transform={"none"}
                     imgSrc={cardInfo.largeImageUri}
                     removeCard={empty}
                  />
               </Box>
            );
         });
      } else return [];
   }, [highImpactCards]);

   const gameWonByCardsToShow = useMemo(() => {
      const numOfCards = gameWonByCards.length;
      if (numOfCards > 0) {
         const gap = 100 / (numOfCards + 1);
         return gameWonByCards.map((cardInfo, i) => {
            const cardLeft = `calc(${(i + 1) * gap}% - ${cardWidth * 0.5}vw)`;
            const cardWrapperSx = { ...cardWrapperBasicSx, left: cardLeft };
            return (
               <Box sx={cardWrapperSx}>
                  <CardWithButtonsComponent
                     showButtons={false}
                     cardWidth={cardWidth}
                     cardWidthUnit={"vw"}
                     transform={"none"}
                     imgSrc={cardInfo.largeImageUri}
                     onClick={() => selectedGameWonByCardIndex !== i && setSelectedGameWonByCardIndex(i)}
                     removeCard={empty}
                  />
               </Box>
            );
         });
      } else return [];
   }, [gameWonByCards, selectedGameWonByCardIndex]);

   const allPlayers = [existingGame.player1, existingGame.player2, existingGame.player3];
   existingGame?.player4 && allPlayers.push(existingGame.player4);

   const canEdit =
      (existingGame?.status !== "APPROVED" || allPlayers.every((p) => p.user.id === ANONYMOUS_USER_ID)) &&
      existingGame?.creator.id === userData?.user?.uid;
   const myPlayer = allPlayers.find((p) => p.user.id === userData?.user?.uid);
   const needsApprovalFromMe = myPlayer != null && myPlayer.status !== "APPROVED";
   const canDispute = existingGame?.status !== "APPROVED" && myPlayer != null && myPlayer?.status !== "DISPUTED";

   return (
      <Box sx={rootContainerSx}>
         {createOrUpdateGameButtonElement ? (
            <Box sx={createGameButtonWrapperSx}>{createOrUpdateGameButtonElement}</Box>
         ) : (
            <ButtonGroup sx={{ margin: "3vh auto" }} color={"secondary"}>
               {ownsGame && (
                  <Button variant={"contained"} color={"warning"} onClick={() => setShowDeleteDialog(true)}>
                     Delete
                  </Button>
               )}
               {canEdit && (
                  <Button variant={"contained"} onClick={() => nav("/editGame/" + existingGame?.id)}>
                     Edit
                  </Button>
               )}
               {needsApprovalFromMe && (
                  <Button variant={"contained"} onClick={approveGameCb}>
                     Approve
                  </Button>
               )}
               {canDispute && (
                  <Button variant={"contained"} onClick={disputeGameCb}>
                     Dispute
                  </Button>
               )}
            </ButtonGroup>
         )}
         <WinnerDropDown
            editable={false}
            winningUser={winningPlayer}
            setWinningUser={empty}
            rootStyle={{ margin: theme.spacing(1) }}
         />

         <Box sx={optionalDataContainerSx}>
            <Typography sx={optionalDataContainerTitleSx}>{"optional data"}</Typography>
            {endingTurns != null && (
               <FormControl variant={"outlined"}>
                  <InputLabel>Ending turn</InputLabel>
                  <Select
                     size={"small"}
                     sx={endingTurnFieldSx}
                     IconComponent={() => null}
                     readOnly={false}
                     defaultValue={0}
                     value={endingTurns}
                     label="Ending turn"
                  >
                     {endingTurnOptions}
                  </Select>
               </FormControl>
            )}
            {winCondition != null && (
               <FormControl variant={"outlined"}>
                  <InputLabel>Win condition used</InputLabel>
                  <Select
                     size={"small"}
                     sx={winConditionFieldSx}
                     readOnly={false}
                     value={winCondition}
                     label="Win condition used"
                  >
                     {winConditions}
                  </Select>
               </FormControl>
            )}
            <Box sx={cardsContainerSx}>
               <Typography sx={cardsContainerTitleSx}>{"Game defining cards"}</Typography>
               {highImpactCardsToShow}
            </Box>
            <Box sx={cardsContainerSx}>
               <Typography sx={cardsContainerTitleSx}>{"Win condition cards"}</Typography>
               {gameWonByCardsToShow}
            </Box>
         </Box>
         <CardPickerComponent
            showCardPicker={showHighImpactCardPicker}
            closeCardPickerHandler={() => setShowHighImpactCardPicker(false)}
            disallowedCards={[]}
            filterCommanders={false}
            filterBackgrounds={false}
            filterPartners={false}
            filterFriendsForever={false}
            selectCardHandler={empty}
         />
         <CardPickerComponent
            showCardPicker={showWinConditionCardPicker}
            closeCardPickerHandler={() => setShowWinConditionCardPicker(false)}
            disallowedCards={[]}
            filterCommanders={false}
            filterBackgrounds={false}
            filterPartners={false}
            filterFriendsForever={false}
            selectCardHandler={empty}
         />
         <DeleteGameConfirmDialog
            open={showDeleteDialog}
            onOk={deleteGame}
            onCancel={() => setShowDeleteDialog(false)}
         />
      </Box>
   );
};

export default MobileOptionalGameDataStepComponent;
