import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Fade, Slide, Step, StepButton, Stepper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import PromoterBanner from "./PromoterBanner";
import { useLocation, useNavigate } from "react-router-dom";
import { toPromotersInfo, toPromotersList } from "../../navPaths";
import TilesContainer from "./TilesContainer";
import { PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH } from "../../UI_CONST";
import CreateOrEditPromoterDialog from "../CreateOrEditPromoterDialog";

const rootSx: SxProps = {
   position: "relative",
   width: "100%",
   height: "100%",
   overflow: "clip",
   display: "flex",
   boxSizing: "border-box",
};

const stepperTextSx: SxProps = {
   fontSize: "1.2em",
   textShadow: "0 0 1px black, 0 0 2px black, 0 0 3px black, 0 0 4px black, 0 0 5px black",
};

const stepperWrapperSx: SxProps = {
   position: "absolute",
   width: `${PROMOTER_PAGE_STEPPER_WRAPPER_WIDTH}px`,
   top: 0,
   left: 0,
   height: "100%",
   display: "flex",
   padding: "10px",
   boxSizing: "border-box",
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
   backdropFilter: "blur(5px)",
};

const stepperSx: SxProps = {
   margin: "auto",
};

const bannerBgImgSx: SxProps = {
   position: "absolute",
   left: 0,
   top: 0,
   width: "100%",
   height: "100%",
   objectFit: "cover",
};

const steps = ["What is a promoter account?", "View promoter accounts"];

const PromotersViewPage: FC<{}> = () => {
   const nav = useNavigate();
   const location = useLocation();
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [activeStep, setActiveStep] = useState<number>(Number(localStorage.getItem("promoterPageActiveStep")) || 0);
   const [openCreatePromoter, setOpenCreatePromoter] = useState(false);
   const handleStep = useCallback((step: number) => {
      setActiveStep(step);
      localStorage.setItem("promoterPageActiveStep", String(step));
   }, []);
   const path = location.pathname;

   useEffect(() => {
      if (activeStep === 0) {
         nav(toPromotersInfo());
      } else if (activeStep === 1) {
         nav(toPromotersList());
      }
   }, [activeStep, nav, path]);

   const stepper = useMemo(
      () =>
         smallScreen ? (
            <></>
         ) : (
            <Slide in={true} direction={"right"}>
               <Box sx={stepperWrapperSx}>
                  <Stepper sx={stepperSx} nonLinear activeStep={activeStep} orientation={"vertical"}>
                     {steps.map((label, index) => (
                        <Step key={label}>
                           <StepButton color="inherit" onClick={() => handleStep(index)}>
                              <Typography sx={stepperTextSx}>{label}</Typography>
                           </StepButton>
                        </Step>
                     ))}
                  </Stepper>
               </Box>
            </Slide>
         ),
      [activeStep, handleStep, smallScreen]
   );

   return (
      <Box sx={rootSx}>
         <Fade in={true} timeout={1000}>
            <Box component="img" sx={bannerBgImgSx} src={"/promoterBannerBgImg.jpg"} />
         </Fade>
         <PromoterBanner
            show={activeStep === 0}
            toList={() => {
               setActiveStep(1);
               localStorage.setItem("promoterPageActiveStep", "1");
            }}
            onClickCreate={() => setOpenCreatePromoter(true)}
         />
         <TilesContainer show={activeStep === 1} />
         {stepper}
         {openCreatePromoter && (
            <CreateOrEditPromoterDialog open={openCreatePromoter} close={() => setOpenCreatePromoter(false)} />
         )}
      </Box>
   );
};

export default PromotersViewPage;
