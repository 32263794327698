import React, { useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme } from "@mui/material";
import { Procedure } from "../../utils";
import useRemoteTournament, { RemoteTournament } from "../useRemoteTournament";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useStageApi } from "../../rest.client/useStagesApi";
import { convertStageResponseToRequest } from "./stageView/useRemoteStage";
import { useNavigate } from "react-router-dom";
import { toTournamentDetails } from "../../navPaths";
import Spinner from "../../common/Spinner";
import { usePromotersApi } from "../../rest.client/usePromotersApi";

export default function CloneTournamentDialog({
   open,
   close,
   remoteTournament,
}: {
   open: boolean;
   close: Procedure;
   remoteTournament: RemoteTournament;
}) {
   const theme = useTheme();
   const {
      createStage: { call: createStageCall },
      getStageFull: { call: getFullStage },
      loading,
   } = useStageApi();
   const {
      getFullPromoter: { call: getFullPromoterCall },
      linkTournamentToPromoter: { call: linkTournamentCall },
   } = usePromotersApi();
   const newRemoteTournament = useRemoteTournament();
   const newSaveRequest = newRemoteTournament.saveRequest;
   const nav = useNavigate();
   const oldStages = remoteTournament.tournament?.stages || [];
   const oldPromoter = remoteTournament.tournament?.promoter;

   useEffect(() => {
      if (remoteTournament.saveRequest && newRemoteTournament.saveRequest == null) {
         newRemoteTournament.setSaveRequest({
            ...remoteTournament.saveRequest,
            name: remoteTournament.saveRequest.name + " - clone",
            //we do this after
            promoterId: undefined,
            endDate: undefined,
         });
      }
   }, [newRemoteTournament, remoteTournament.saveRequest]);

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         fullWidth={true}
         maxWidth={"sm"}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">{"Clone tournament"}</DialogTitle>
         <DialogContent>
            <Spinner show={loading} />
            {newRemoteTournament.saveRequest && newSaveRequest && !loading && (
               <Box
                  sx={{
                     marginTop: theme.spacing(1),
                     display: "flex",
                     flexDirection: "column",
                     gap: theme.spacing(2),
                  }}
               >
                  <TextField
                     fullWidth
                     required
                     size={"small"}
                     variant={"outlined"}
                     label={"Cloned tournament name"}
                     onChange={(e) =>
                        newRemoteTournament.setSaveRequest({
                           ...newSaveRequest,
                           name: e.target.value,
                        })
                     }
                     value={newSaveRequest.name}
                     inputProps={{ maxLength: 100 }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <DatePicker
                        sx={{ width: "100%" }}
                        format={"DD/MM/YYYY"}
                        label={"Start date"}
                        value={dayjs(newSaveRequest.startDate)}
                        onChange={(newDate: Dayjs | null) => {
                           newDate != null &&
                              newRemoteTournament.setSaveRequest({
                                 ...newSaveRequest,
                                 startDate: newDate.toDate(),
                              });
                        }}
                        views={undefined}
                     />
                  </LocalizationProvider>
                  {newSaveRequest.registrationMode === "PASSWORD" && (
                     <TextField
                        fullWidth
                        required
                        type={"password"}
                        size={"small"}
                        variant={"outlined"}
                        label={"Password"}
                        onChange={(e) =>
                           newRemoteTournament.setSaveRequest({
                              ...newSaveRequest,
                              password: e.target.value,
                           })
                        }
                        value={newSaveRequest.password}
                        inputProps={{ maxLength: 100 }}
                     />
                  )}
               </Box>
            )}
         </DialogContent>
         <DialogActions>
            <Button
               onClick={async () => {
                  const newTournament = await newRemoteTournament.saveTournament();
                  for (const oldStage of oldStages) {
                     const fullStage = await getFullStage({ pathParams: { id: oldStage.id } });
                     await createStageCall({ body: { ...convertStageResponseToRequest(newTournament.id, fullStage) } });
                  }
                  if (oldPromoter) {
                     const fullPromoter = await getFullPromoterCall({ pathParams: { id: oldPromoter.id } });
                     if (fullPromoter.isAdmin) {
                        await linkTournamentCall({
                           pathParams: { id: fullPromoter.id },
                           body: { tournamentId: newTournament.id },
                        });
                     }
                  }
                  nav(toTournamentDetails(newTournament.id));
                  close();
               }}
               disabled={loading}
            >
               Clone
            </Button>
            <Button onClick={close} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
