import React, { CSSProperties, useMemo } from "react";
import { Box, Button, Grow, keyframes, Typography, useMediaQuery, useTheme } from "@mui/material";
import useCurrentUser from "../../auth/useCurrentUser";
import { SxProps } from "@mui/system";
import { Procedure } from "../../utils";
import { useNavigate } from "react-router-dom";
import { ALL_TOURNAMENTS_PATH, LOGIN_PATH } from "../../index";
import { MAIN_PAGE_TOURNAMENTS } from "../../UI_CONST";

const glowing = keyframes`
  from {
    box-shadow: 0 0 1vmin 0.2vmin black, 0 0 1.2vmin 0.2vmin black
  }
  to {
    box-shadow: 0 0 1vmin 0.2vmin black, 0 0 1.2vmin 0.2vmin cyan
  }
`;

const textWrapperSx: SxProps = {
   position: "relative",
   width: "100%",
   flexGrow: 1,
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   alignContent: "center",
   alignItems: "center",
   background: "linear-gradient(to bottom, black 0%, transparent 50%, black 100%)",
   // backdropFilter: "blur(3px)",
   // backgroundColor: "rgba(255,255,0,0.3)",
};

const title1Sx: SxProps = {
   margin: "auto",
   marginTop: "0",
   fontSize: { xs: "3em", sm: "6em", md: "7.5em" },
   fontFamily: "Neon",
   fontWeight: 400,
   fontStyle: "italic",
   pointerEvents: "none",
   textShadow:
      "0 0 1px black, 0 0 2px black, 0 0 3px black, 0 0 4px black, 0 0 5px black, 0 0 6px rgb(191, 254, 255), 0 0 7px rgb(191, 254, 255), 0 0 8px rgb(191, 254, 255), 0 0 9px white, 0 0 10px white",
};

const title2Sx: SxProps = {
   padding: "0 10px 0 10px",
   fontSize: { xs: "1em", md: "1.5em" },
   // fontWeight: 300,
   letterSpacing: 2,
   lineHeight: 1.4,
   margin: "auto auto 0 auto",
   textAlign: "center",
   textShadow: "0 0 1px black, 0 0 2px black, 0 0 3px black, 0 0 4px black, 0 0 5px black, 0 0 6px black",
   pointerEvents: "none",
};

const btnWrapperSx: SxProps = {
   margin: "10px auto 10px auto",
   display: "flex",
   flexDirection: { xs: "column", md: "row" },
   gap: "10px",
   alignItems: "center",
};

const createBtnSx: SxProps = {
   boxShadow: `0 0 1vmin 0.2vmin rgba(0,0,0,1), 0 0 1.2vmin 0.2vmin white`,
   fontSize: { xs: "1em", sm: "1.5em" },
   animation: `${glowing} 2s infinite alternate`,
   textShadow: "0 0 1px black, 0 0 2px black, 0 0 3px black",
};

const viewBtnSx: SxProps = {
   fontSize: { xs: "1em", sm: "1.5em" },
   textShadow: "0 0 1px black, 0 0 2px black, 0 0 3px black",
};

const highlightCss: CSSProperties = {
   fontWeight: "bolder",
   color: "cyan",
   fontStyle: "italic",
   textShadow:
      "0 0 1px white, 0 0 2px white, 0 0 3px black, 0 0 4px black, 0 0 5px black, 0 0 6px black, 0 0 7px black, 0 0 8px black",
};

export default function WelcomeContent({ onCreateClicked }: { onCreateClicked: Procedure }) {
   const theme = useTheme();
   const nav = useNavigate();
   const user = useCurrentUser();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const btn = useMemo(() => {
      return (
         <Grow in={true} timeout={2000}>
            <Box sx={btnWrapperSx}>
               <Button
                  size={smallScreen ? "small" : "large"}
                  sx={createBtnSx}
                  variant={"contained"}
                  onClick={
                     user.loggedIn
                        ? () => {
                             onCreateClicked();
                             localStorage.setItem("mainPageContent", MAIN_PAGE_TOURNAMENTS);
                          }
                        : () => nav(LOGIN_PATH)
                  }
               >
                  {user.loggedIn ? "BUILD YOUR OWN!" : "SIGN UP AND BUILD YOUR OWN!"}
               </Button>
               <Typography>OR</Typography>
               <Button
                  size={smallScreen ? "small" : "large"}
                  color={"secondary"}
                  sx={viewBtnSx}
                  variant={"outlined"}
                  onClick={() => {
                     localStorage.setItem("mainPageContent", MAIN_PAGE_TOURNAMENTS);
                     nav(ALL_TOURNAMENTS_PATH);
                  }}
               >
                  {"View tournaments"}
               </Button>
            </Box>
         </Grow>
      );
   }, [nav, onCreateClicked, smallScreen, user.loggedIn]);

   return (
      <Box sx={textWrapperSx}>
         <Grow in={true} timeout={1000}>
            <Typography sx={title1Sx} color={theme.palette.primary.main}>
               Brainstorm
            </Typography>
         </Grow>
         <Grow in={true} timeout={1500}>
            <Typography sx={title2Sx}>
               Brainstorm TMS, the free tournament management system for cEDH with{" "}
               <span style={highlightCss}>UNLIMITED</span> customization!
            </Typography>
         </Grow>
         {btn}
      </Box>
   );
}
