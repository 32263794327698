import React, { useMemo } from "react";
import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { RoundFullResponse } from "../../rest.client/useStagesApi";
import MatchPairingDataRow from "./MatchPairingDataRow";

const rootSx: SxProps = {
   position: "relative",
   width: "100%",
   marginBottom: "10px",
};

interface IRoundTimerExtraInfo {
   roundData: RoundFullResponse;
}

export default function RoundTimerExtraInfo({ roundData }: IRoundTimerExtraInfo) {

   const content = useMemo(
      () =>
         roundData.matches.length > 0 ? (
            roundData.matches.map((m) => <MatchPairingDataRow matchData={m} />)
         ) : (
            <Typography>No pairing info</Typography>
         ),
      [roundData.matches]
   );

   return (
      <Card sx={rootSx}>
         <CardHeader subheader={`Round ${roundData.roundNumber}`} />
         <Divider />
         <CardContent>{content}</CardContent>
      </Card>
   );
}
