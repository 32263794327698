import React, { useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { MatchResultWinnerOnlyResponse } from "../../rest.client/useStagesApi";
import UserChip from "../../common/UserChip";
import WinnerIcon from "../../gameFeatures/WinnerIcon";

const rootSx: SxProps = {
   position: "relative",
   width: "100%",
   padding: "10px",
   display: "flex",
   flexWrap: "wrap",
   gap: "5px",
};

const titleSx: SxProps = {
   width: "100%",
};

interface IMatchPairingDataRow {
   matchData: MatchResultWinnerOnlyResponse;
}

export default function MatchPairingDataRow({ matchData }: IMatchPairingDataRow) {

   const theme = useTheme();
   const textColor = useMemo(
      () =>
         matchData.matchStatus === "PENDING"
            ? theme.palette.warning.main
            : matchData.matchStatus === "APPROVED"
            ? theme.palette.success.main
            : theme.palette.error.main,
      [matchData.matchStatus, theme.palette.error, theme.palette.success, theme.palette.warning]
   );

   return (
      <Box sx={rootSx}>
         <Typography
            sx={titleSx}
            color={textColor}
         >{`Match ${matchData.matchNumber} - ${matchData.matchStatus}`}</Typography>
         {matchData.matchResults.map((matchResult) => (
            <UserChip
               user={matchResult.registration.user}
               children={
                  matchResult.result === "FIRST" ? <WinnerIcon won={true} readonly={true} onClick={() => {}} /> : <></>
               }
            />
         ))}
      </Box>
   );
}
