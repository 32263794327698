import React, { useState } from "react";
import { IconButton } from "@mui/material";
import NavigationDrawer from "./NavigationDrawer";

const HeaderLogo: React.FC = () => {
   const [openDrawer, setOpenDrawer] = useState(false);

   return (
      <>
         <IconButton
            id={"headerLogoRoot"}
            sx={{
               cursor: "pointer",
               display: "flex",
               height: "56px",
               width: "56px",
               alignItems: "center",
            }}
            onClick={() => setOpenDrawer(true)}
         >
            <img
               src={"/logo512.png"}
               alt={"Brainstorm logo"}
               style={{
                  pointerEvents: "none",
                  height: "60px",
               }}
            />
         </IconButton>
         <NavigationDrawer
            open={openDrawer}
            onClose={() => {
               console.log("ahhh");
               setOpenDrawer(false);
            }}
         />
      </>
   );
};

export default HeaderLogo;
